import React, { useEffect } from 'react';

const useClickOutside = (
        ref: React.RefObject<HTMLDivElement | HTMLInputElement | HTMLButtonElement>,
        callBack: () => void
    ) => {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callBack();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [ref]);
}

export default useClickOutside;