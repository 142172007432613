import React, {useState, useEffect} from 'react';
import {
    Container, 
    Table, 
    TableCell, 
    TableBody, 
    TableFooter, 
    Accordion,
    Card,
    CardContent,
    Select,
    Grid,
    TableContainer, 
    TableHead, 
    TableRow,
    TablePagination,
    Toolbar,
    Typography,
    MenuItem,
    AccordionDetails,
    AccordionSummary,
    InputLabel,
    FormControl,
    TextField
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Row} from 'react-bootstrap';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import './style.scss';
import moment from 'moment';
import Auth from '../../auth';
import { useHistory } from 'react-router-dom';
import { MoneyLabel } from '../../components';

const PaymentSchedules: React.FC<{
    admin: any;
    setLoadingOverlay: (e: boolean) => void;
}> = ({
    admin,
    setLoadingOverlay
}) => {
    const history = useHistory();
    const [date, setDate] = useState<string>(moment().format("YYYY-MM-DD").toString())
    const [transactions, setTransactions] = useState<any | null>(null);
    const [count, setCount] = useState<any | null>(0);
    const [args, setArgs] = useState<any | null>(`?date=${date}`);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [pageSize, setPageSize] = useState<any | null>(10)
    const handleChangePage = (event: unknown, newPage: number) => {
        if(newPage !== page){
            console.log('PAGE: ',newPage+1)
            setPage(newPage);
            
        }
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPageSize(event.target.value);
        setPage(0);
    };

    useEffect(() => {
        if(transactions === null) {
            loadTransactions(args)
        }
    })

    useEffect(() => {
        let newArgs = `${args}${(page === 0 )? '' : `&page=${page}`}&pageSize=${pageSize}`;
        loadTransactions(newArgs)
    },[pageSize, args])

    const changeDate = () => {
        setArgs(`?date=${date}`)
    }

    const loadTransactions = (args: any) => {
        setLoadingOverlay(true)
        Auth.dashboard.getPaymentSchedules(
            args,
            (resp) => {
                setTransactions(resp.results)
                setCount(resp.count)
                setLoadingOverlay(false)
            },
            (e) => {
                console.log(e)
                setLoadingOverlay(false)
            }
        )
    }

    return (
        <>
            <div className="page-content payment-schedules">
                <Container>
                    <Row>

                    </Row>
                    <Row>
                        <Card>
                            <CardContent>
                                <Grid container alignItems="center" spacing={2} direction="row" justify="center">
                                    <Grid item>
                                        <TextField
                                            id="date"
                                            label="Date"
                                            type="date"
                                            value={date}
                                            onChange={(e) => setDate(e.target.value)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <div className="button-small-tool" onClick={changeDate}>
                                            <Typography style={{fontSize: 16, color: 'white'}}>Filter Transactions</Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography style={{fontSize: '16px', fontWeight: 'bold'}} color="primary">
                                                        Reference
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography style={{fontSize: '16px', fontWeight: 'bold'}} color="primary">
                                                        Amount
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography style={{fontSize: '16px', fontWeight: 'bold'}} color="primary">
                                                        Status
                                                    </Typography>
                                                </TableCell>
                                                
                                                <TableCell>
                                                    <Typography style={{fontSize: '16px', fontWeight: 'bold'}} color="primary">
                                                        Interval Date
                                                    </Typography>
                                                </TableCell>
                                               
                                                
                                                
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {transactions && transactions.length > 0 ? transactions.map((obj: any) => {
                                                console.log(obj)
                                                return (
                                                    
                                                    <TableRow className="transaction-row" onClick={() => history.push(`/admin/transaction/${obj.transaction}`)}>
                                                        <TableCell>
                                                            {obj.reference}
                                                        </TableCell>
                                                        <TableCell>
                                                            <MoneyLabel style={'money-label'} value={obj.amount} isMoney={true}/> 
                                                        </TableCell>
                                                        <TableCell>
                                                            {obj.status}
                                                        </TableCell>
                                                        
                                                        <TableCell>
                                                            {moment(obj.interval_date ? obj.interval_date : obj.created).format("MMMM D, YYYY h:mm A")}
                                                        </TableCell>
                                                    
                                                    </TableRow>
                                                    
                                                )
                                            }): 
                                                <TableRow>
                                                    <TableCell colSpan={6}>
                                                        No data... Please use the date filtering tool above.
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                        <TableFooter>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 100]}
                                                count={count}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onChangePage={handleChangePage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                            />
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default PaymentSchedules;