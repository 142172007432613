import React, {useState, useEffect} from 'react';
import {
    Container, 
    Checkbox,
    FormControlLabel,
    Card,
    CardContent,
    Grid,
    TextField,
    Divider,
    Typography,
    MenuItem,
    Select,
    InputLabel
} from '@material-ui/core'
import './style.scss';
import {
    BottomToolbar
} from '../../components'
import { useHistory, useParams } from 'react-router-dom';
import {
    ParamTypes
} from '../../interface';
import Auth from '../../auth';


let test =  
    {
        "id": 904,
        "name": "Testing Product 4",
        "created": "2021-03-17T06:53:14.904172Z",
        "modified": "2021-03-17T06:53:14.930748Z",
        "end_date": "2021-03-17T06:53:14.904172Z",
        "description": "312",
        "tag": null,
        "merchant": null,
        "buyer": 281,
        "amount": "123.00",
        "terms": 1,
        "transaction_fee": "5.12",
        "monthly": "128.12",
        "interest_charge": "0.00",
        "interest": 0,
        "status": "WAITING",
        "category": "STRAIGHT",
        "proposal_type": "BUYING"
    }



const ProposalDetails: React.FC<{
    setLoadingOverlay: (e?: boolean) => void;
}> = ({setLoadingOverlay}) => {
    const { id } = useParams<ParamTypes>();
    const [users, setUsers] = useState<any | null>(null);
    const [name, setName] = useState<string | null>('')
    const [desc, setDesc] = useState<string | null>('')
    const [merchant, setMerchant] = useState<any | null>(null)
    const [buyer, setBuyer] = useState<any | null>(null)
    const [amount, setAmount] = useState<any | null>('')
    const [monthly, setMonthly] = useState<any | null>('')
    const [transact_fee, setTransactFee] = useState<any | null>('')
    const [interest, setInterest] = useState<any | null>('')
    const [interest_charge, setInterestCharge] = useState<any | null>('')
    const [terms, setTerms] = useState<any | null>('')
    const [status, setStatus] = useState<any | null>('')
    const [reference, setReference] = useState<any | null>('')
    const [category, setCategory] = useState<any | null>('')
    const [isShoulderedByMerchant, setIsShoulderedByMerchant] = useState<boolean>(false);
    const [proposalType, setProposalType] = useState<string | null>('')

    useEffect(() => {
        if(id && name === ''){
            setLoadingOverlay(true)
            Auth.dashboard.getProposalsDetails(
                id,
                (resp) => {
                    Auth.dashboard.getUsers(
                        '?page_size=100',
                        (userResp) => {
                            console.log(userResp)
                            setName(resp.name)
                            setDesc(resp.description)
                            setMerchant(resp.merchant)
                            setBuyer(resp.buyer)
                            setLoadingOverlay(false)
                            setAmount(resp.amount)
                            setReference(resp.reference)
                            setMonthly(resp.monthly)
                            setTransactFee(resp.transaction_fee)
                            setInterest(resp.interest)
                            setInterestCharge(resp.interest_charge)
                            setTerms(resp.terms)
                            setStatus(resp.status)
                            setCategory(resp.category_name)
                            setIsShoulderedByMerchant(false)
                            setUsers(userResp.results)
                        },
                        (e) => {
                            setLoadingOverlay(false)
                            console.log(e)
                        }
                    )
                    

                },
                (e) => {
                    setLoadingOverlay(false)
                    console.log(e)
                }
            )
        }
    })


    const updateProposal = () => {
        setLoadingOverlay(true)
        Auth.dashboard.editProposalDetails(
            id,
            name,
            desc,
            merchant,
            buyer,
            amount,
            monthly,
            transact_fee,
            interest,
            interest_charge,
            terms,
            status,
            category,
            (resp) => {
                console.log(resp)
                setLoadingOverlay(false)
            },
            (e) => {
                console.log(e)
                setLoadingOverlay(false)
            }
        )
    }

    return(
        <>
        <div className="page-content proposal-details">
            <div className="proposal-details-container">
                <Container fixed>
                <Typography color="primary" style={{fontSize: 16, padding: 15}}>Reference: {reference}</Typography>
                    <Grid container direction="row" alignItems="center" justify="center" xs={12} sm={12} spacing={5}>
                        <Grid container direction="column"  spacing={2} xs={12} sm={7} item>
                            <Card>
                                <CardContent>

                                
                                    <Grid item style={{paddingBottom: '20px'}}>
                                        <Typography color="primary" style={{fontSize: 16}}>Proposal Details </Typography>
                                    </Grid>
                                    <Grid container style={{padding: '10px 0px'}} alignItems="center" justify="space-between">
                                        <Grid item>
                                            <Typography color="primary" style={{fontSize: 16}}>Name: </Typography>
                                        </Grid>
                                        <Grid item>
                                            {name}
                                        </Grid>
                                    </Grid>
                                    <div style={{paddingTop:'20px', paddingBottom: '20px'}}>
                                            <Divider />
                                        </div>
                                    <Grid container item spacing={2} direction="row" >
                                        <Grid container xs={12} spacing={2} item alignItems="center" justify="space-between" direction="row">
                                            <Grid item>
                                                <Typography color="primary" style={{fontSize: 16}}>Status: </Typography>
                                            </Grid>
                                            <Grid item>
                                                {/** 
                                                 * 
                                                 * <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={status}
                                                    fullWidth
                                                    variant="outlined"
                                                    onChange={(e) => setStatus(e.target.value)}
                                                >
                                                    <MenuItem value={'WAITING'}>Waiting</MenuItem>
                                                    <MenuItem value={'PENDING'}>Pending</MenuItem>
                                                    <MenuItem value={'ACCEPTED'}>Accepted</MenuItem>
                                                    <MenuItem value={'CURRENT'}>Current</MenuItem>
                                                    <MenuItem value={'COMPLETE'}>Complete</MenuItem>
                                                    
                                                </Select>
                                                    * 
                                                */}
                                                {status}
                                            </Grid>
                                        </Grid>
                                        
                                    </Grid>
                                    <div style={{paddingTop:'20px', paddingBottom: '20px'}}>
                                            <Divider />
                                        </div>
                                    <Grid container style={{padding: '10px 0px'}} alignItems="center" justify="space-between">
                                        <Grid item>
                                            <Typography color="primary" style={{fontSize: 16}}>Description: </Typography>
                                        </Grid>
                                        <Grid item>
                                            {desc}
                                        </Grid>
                                    </Grid>
                                    {/**
                                     *   <Grid item>
                                        <TextField 
                                            value={name} 
                                            name="name" 
                                            fullWidth
                                            label={<Typography>Name</Typography>}
                                            margin="normal"
                                            variant="outlined"
                                            disabled={true}
                                            onChange={(e) => setName(e.target.value)} 
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField 
                                            value={desc} 
                                            name="desc" 
                                            fullWidth
                                            label={<Typography>Description</Typography>}
                                            multiline
                                            rows={4}
                                            margin="normal"
                                            variant="outlined"
                                            onChange={(e) => setDesc(e.target.value)} 
                                        />
                                    </Grid>
                                     * 
                                     */}
                                  
                                    
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid container direction="column" spacing={2} xs={12} sm={5} item>
                                <Card>
                                    <CardContent>
                                        
                                        <Grid container alignItems="center" justify="space-between">
                                            <Grid item>
                                                <Typography color="primary" style={{fontSize: 16}}>Merchant: </Typography>
                                            </Grid>
                                            <Grid item>
                                                
                                                {users && users.map((user: any) => {
                                                    console.log(user);
                                                    if(user.id === merchant){
                                                        return user.email
                                                    }else{
                                                        return null;
                                                    }
                                                    
                                                })}
                                            </Grid>
                                        </Grid>
                                        <div style={{paddingTop:'20px', paddingBottom: '20px'}}>
                                            <Divider />
                                        </div>
                                        <Grid container alignItems="center" justify="space-between">
                                            <Grid item>
                                                <Typography color="primary" style={{fontSize: 16}}>Buyer: </Typography>
                                            </Grid>
                                            <Grid item>
                                                
                                                {users && users.map((user: any) => {
                                                    console.log(user);
                                                    if(user.id === buyer){
                                                        return user.email
                                                    }else{
                                                        return null;
                                                    }
                                                    
                                                })}
                                            </Grid>
                                        </Grid>
                                        <div style={{paddingTop:'20px', paddingBottom: '20px'}}>
                                            <Divider />
                                        </div>
                                        {/**
                                         *<Grid item>
                                            <Typography color="primary" style={{fontSize: 16}}>Buyer: </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={buyer}
                                                fullWidth
                                                variant="outlined"
                                                onChange={(e) => setBuyer(e.target.value)}
                                            >
                                                {users && users.map((user: any) => {
                                                    console.log(user);
                                                    return (
                                                        <MenuItem value={user.id}>{user.email}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </Grid>
                                         * 
                                         */}
                                        
                                       {/***
                                        * 
                                        * <Grid container item spacing={2} direction="row" >
                                            <Grid container xs={12} spacing={2} item alignItems="center" justify="space-between" direction="row">
                                                <Grid item>
                                                    <Typography color="primary" style={{fontSize: 16}}>Status: </Typography>
                                                </Grid>
                                                <Grid item>
                                                    {/** 
                                                     * 
                                                     * <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={status}
                                                        fullWidth
                                                        variant="outlined"
                                                        onChange={(e) => setStatus(e.target.value)}
                                                    >
                                                        <MenuItem value={'WAITING'}>Waiting</MenuItem>
                                                        <MenuItem value={'PENDING'}>Pending</MenuItem>
                                                        <MenuItem value={'ACCEPTED'}>Accepted</MenuItem>
                                                        <MenuItem value={'CURRENT'}>Current</MenuItem>
                                                        <MenuItem value={'COMPLETE'}>Complete</MenuItem>
                                                        
                                                    </Select>
                                                     * 
                                                    }
                                                    {status}
                                                </Grid>
                                            </Grid>
                                          
                                        </Grid>
                                        <div style={{paddingTop:'20px', paddingBottom: '20px'}}>
                                            <Divider />
                                        </div>
                                        * 
                                        * 
                                        */} 
                                        
                                        <Grid container item spacing={1} direction="row">
                                            <Grid container xs={12} spacing={2} item alignItems="center" justify="space-between" direction="row">
                                                <Grid item>
                                                    <Typography color="primary" style={{fontSize: 16}}>Category: </Typography>
                                                </Grid>
                                                <Grid item>
                                                    {category}
                                                </Grid>
                                            </Grid>
                                            
                                        </Grid>
                                    </CardContent>
                                </Card>
                        </Grid>
                    </Grid>
                    <Grid style={{paddingTop: '20px'}} container direction="row" justify="center" item xs={12} spacing={2} sm={12}>
                        <Grid container direction="row" spacing={2} xs={12} item>
                            <Card>
                                <CardContent>

                                
                                <Grid container item direction="row" xs={12} sm={12}  spacing={2} alignItems="center" justify="center">
                                    <Grid item xs={12} sm={3}>
                                        <TextField 
                                            value={amount} 
                                            name="amount" 
                                            fullWidth
                                            label={<Typography>Amount</Typography>}
                                            style={{ margin: '8px 8px 8px 0px' }}
                                            margin="normal"
                                            variant="outlined"
                                            onChange={(e) => setAmount(e.target.value)} 
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField 
                                            value={terms} 
                                            name="terms" 
                                            fullWidth
                                            type="number"
                                            label={<Typography>Terms</Typography>}
                                            style={{ margin: '8px 8px 8px 0px' }}
                                            margin="normal"
                                            variant="outlined"
                                            onChange={(e) => setTerms(e.target.value)} 
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField 
                                            value={monthly} 
                                            name="monthly" 
                                            fullWidth
                                            label={<Typography>Monthly</Typography>}
                                            style={{ margin: '8px 8px 8px 0px' }}
                                            margin="normal"
                                            variant="outlined"
                                            onChange={(e) => setMonthly(e.target.value)} 
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField 
                                            value={transact_fee} 
                                            name="transact_fee" 
                                            fullWidth
                                            label={<Typography>Transaction Fee</Typography>}
                                            style={{ margin: '8px 8px 8px 0px' }}
                                            margin="normal"
                                            variant="outlined"
                                            onChange={(e) => setTransactFee(e.target.value)} 
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField 
                                            value={interest} 
                                            name="interest" 
                                            fullWidth
                                            label={<Typography>Interest</Typography>}
                                            style={{ margin: '8px 8px 8px 0px' }}
                                            margin="normal"
                                            variant="outlined"
                                            onChange={(e) => setInterest(e.target.value)} 
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField 
                                            value={interest_charge} 
                                            name="interest_charge" 
                                            fullWidth
                                            label={<Typography>Interest Charge</Typography>}
                                            style={{ margin: '8px 8px 8px 0px' }}
                                            margin="normal"
                                            variant="outlined"
                                            onChange={(e) => setInterestCharge(e.target.value)} 
                                        />
                                    </Grid>
                                    
                                </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    
                </Container>
            </div>
            <BottomToolbar />
        </div>
        
        </>
    )
}

export default ProposalDetails;