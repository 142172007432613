import React, {useState, useEffect, useRef} from 'react';
import {
    Container, 
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    TextField,
    Divider,
    Typography,
    MenuItem,
    Select,
    InputLabel
} from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import './style.scss';
import {
    BottomToolbar, PasswordConfirmationDialog, RejectApplicationDialog
} from '../../components'
import { useHistory, useParams } from 'react-router-dom';
import {
    ParamTypes
} from '../../interface'
import Auth from '../../auth';
import { useFormFiles, useQuery } from '../../hooks';
import { ErrorDialog } from '../../components/ErrorDialog';
import { SuccessDialog } from '../../components/SuccessDialog';



const SourceDetails: React.FC<{
    setLoadingOverlay: (e?: boolean) => void; 
}> = ({setLoadingOverlay}) => {
    const { id } = useParams<ParamTypes>();
    const [name, setName] = useState('')
    const [owner, setOwner] = useState<any | null>(null);
    const [ownerID, setOwnerID] = useState<number>(0)
    const [cardCutoff, setCardCutoff] = useState<any | null>(0);
    const [cardLimit, setCardLimit] = useState<any | null>('')
    const [spendLimit, setSpendLimit] = useState<any | null>('')
    const [source_type, setSourceType] = useState<any | null>('');
    const [referenceID, setReferenceID] = useState<number>(0)
    const [installmentAuthorized, setInstallmentAuthorized] = useState(false);
    const [Cardstatement, setCardStatement] = useState('');
    const history = useHistory();
    const inputFile = useRef<any | null>(null) 
    const [openPassword, setOpenPassword] = useState(false);
    const [openReject, setOpenReject] = useState(false);
    const imgDefault = 'https://reservation.asiwebres.com/v4/App_Themes/images/noimage.png';
    const {files, handleFiles} = useFormFiles({
        Cardstatement: '',
    })
    const query = useQuery();
    const [openSuccess, setOpenSuccess] = useState(false);
    const [dialogMsg, setDialogMsg] = useState('');
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [error, setError] = useState<string | null>(null)

    const openFile = () => {
        inputFile.current.click()
    }

    useEffect(() => {
        if(files.Cardstatement !== ''){
            Auth.dashboard.editSourceCardStatement(
                id,
                files.Cardstatement,
                (resp) => {
                    setCardStatement(resp.data.Cardstatement)
                },
                (e) => {
                    console.log(e)
                    setError(e.error)
                    setOpenErrorDialog(true)
                }
            )
        }
    },[files])

    useEffect(() => {
        if(id && name === ''){
            setLoadingOverlay(true)
            Auth.dashboard.getSourceDetails(
                id,
                (resp) => {
                    Auth.dashboard.getUsers(
                        '?page_size=100',
                        (userResp) => {
                            setName(resp.name)
                            setOwner(resp.owner.email)
                            setOwnerID(resp.owner.id)
                            setCardCutoff(resp.cardCutoff)
                            setCardLimit(resp.cardLimit)
                            setSpendLimit(resp.spendLimit)
                            setSourceType(resp.source_type)
                            setReferenceID(resp.reference_id)
                            setInstallmentAuthorized(resp.installmentAuthorized)
                            setCardStatement(resp.Cardstatement ? resp.Cardstatement : imgDefault)
                            setLoadingOverlay(false)
                        },
                        (e) => {
                            console.log(e)
                            setError(e.error)
                            setOpenErrorDialog(true)
                            setLoadingOverlay(false)
                        }
                    )
                },  
                (e) => {
                    console.log(e)
                    setError(e.error)
                    setLoadingOverlay(false)
                    setOpenErrorDialog(true)
                }
            )
        }
    })

    const deleteSource = () => {
        setLoadingOverlay(true)
        Auth.dashboard.deleteSource(
            id,
            (resp) => {
                console.log(resp)
                setLoadingOverlay(false)
                history.goBack();
            },
            (e) => {
                console.log(e)
                setLoadingOverlay(false)
                setError(e.error)
                setOpenErrorDialog(true)
            }
        )
    }

    const updateSource = () => {
        setLoadingOverlay(true)
        Auth.dashboard.editSourceDetails(
            id,
            name,
            referenceID,
            source_type,
            installmentAuthorized,
            spendLimit,
            (resp) => {
                console.log(resp)
                setOpenSuccess(true)
                setLoadingOverlay(false)
                setDialogMsg("Source has been successfully updated!")
            },
            (e) => {
                console.log(e)
                setError(e.error)
                setLoadingOverlay(false)
                setOpenErrorDialog(true)
            }
        )
    }

    const updateCardStatement = (e: any) => {
        handleFiles(e)
    }

    return(
        <>
        <div className='page-content source-details'>
            <div className='source-details-container'>
                <Container>
                    <Grid item container direction="row" >
                        <Grid container direction="row" item xs={12} sm={6} alignItems="center" justify="flex-start">
                            <Grid item xs={4}>
                                <InputLabel id="demo-simple-select-label">Name: </InputLabel>
                            </Grid>
                            <Grid  item>
                   
                                <TextField 
                                    value={name} 
                                    name="name" 
                                    fullWidth
                                    style={{ margin: '8px 8px 8px 0px' }}
                                    margin="normal"
                                    onChange={(e) => setName(e.target.value)} 
                                />
                          
                            </Grid>
                            
                        </Grid>
                       
                    </Grid>
                    <Divider variant={"fullWidth"} style={{fontSize: '20px', margin: '20px 0px 20px'}}/>
                    <Grid item container direction="row" >
                        <Grid container direction="row" item xs={12} sm={6} alignItems="center" justify="flex-start">
                            <Grid item xs={4}>
                                <InputLabel id="demo-simple-select-label">Owner: </InputLabel>
                            </Grid>
                            <Grid item>
                                
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={owner}
                                    fullWidth
                                    style={{minWidth: '190px'}}
                                    onChange={(e) => setOwner(e.target.value)}
                                >
                                    <MenuItem value={owner}>{owner}</MenuItem>
                                </Select>
                          
                            </Grid>
                            
                        </Grid>
                       
                    </Grid>
                    <Divider variant={"fullWidth"} style={{fontSize: '20px', margin: '20px 0px 20px'}}/>
                    <Grid item container direction="row" >
                        <Grid container direction="row" item xs={12} sm={6} alignItems="center" justify="flex-start">
                            <Grid item xs={4}>
                                <InputLabel id="demo-simple-select-label">Source Type: </InputLabel>
                            </Grid>
                            <Grid item>
                                
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={source_type}
                                    fullWidth
                                    style={{minWidth: '190px'}}
                                    onChange={(e) => setSourceType(e.target.value)}
                                >
                                    <MenuItem value={'PAYMAYA'}>Paymaya</MenuItem>
                                </Select>
                          
                            </Grid>
                            
                        </Grid>
                       
                    </Grid>
                    <Divider variant={"fullWidth"} style={{fontSize: '20px', margin: '20px 0px 20px'}}/>
                    <Grid item container direction="row" >
                        <Grid container direction="row" item xs={12} sm={6} alignItems="center" justify="flex-start">
                            <Grid item xs={4}>
                                <InputLabel id="demo-simple-select-label">Card Statement: </InputLabel>
                            </Grid>
                            <Grid item>
                                <div className="image-container">
                                    <input name='Cardstatement' type="file" onChange={updateCardStatement} ref={inputFile} style={{display: 'none'}}/>
                                    <img className="image" src={Cardstatement}/>
                                    <div className="image-buttons">
                                        <IconButton style={{width: '30px', height: '30px'}} onClick={openFile}>
                                            <EditIcon fontSize={'small'} style={{fill: 'green'}}/>
                                        </IconButton>
                                        <IconButton style={{width: '30px', height: '30px'}}>
                                            <VisibilityIcon fontSize={'small'} style={{fill: 'blue'}}/>
                                        </IconButton>
                                        
                                    </div>
                                </div>
                            </Grid>
                            
                        </Grid>
                       
                    </Grid>
                    <Divider variant={"fullWidth"} style={{fontSize: '20px', margin: '20px 0px 20px'}}/>
                    <Grid item container direction="row">
                        <Grid container direction="row" item xs={12} alignItems="center" justify="flex-start">
                            <Grid item>
                                <FormControlLabel
                                    control={<Checkbox checked={installmentAuthorized} onChange={() => setInstallmentAuthorized(!installmentAuthorized)} name="active" />}
                                    label={<Typography style={{fontSize: '16px'}}>Installment Authorized</Typography>}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider variant={"fullWidth"} style={{fontSize: '20px', margin: '20px 0px 20px'}}/>
                    <Grid item container direction="row" >
                        <Grid container direction="row" item xs={12} sm={6} alignItems="center" justify="flex-start">
                            <Grid item xs={4}>
                                <InputLabel id="demo-simple-select-label">Card Cutoff: </InputLabel>
                            </Grid>
                            <Grid item>
                                <TextField 
                                    value={cardCutoff} 
                                    name="cardCutoff" 
                                    fullWidth
                                    style={{ margin: '8px 8px 8px 0px' }}
                                    margin="normal"
                                    type="number"
                                    disabled
                                    onChange={(e) => setCardCutoff(e.target.value)} 
                                />
                            
                            </Grid>
                            
                        </Grid>
                       
                    </Grid>
                    <Divider variant={"fullWidth"} style={{fontSize: '20px', margin: '20px 0px 20px'}}/>
                    <Grid item container direction="row" >
                        <Grid container direction="row" item xs={12} sm={6} alignItems="center" justify="flex-start">
                            <Grid item xs={4}>
                                <InputLabel id="demo-simple-select-label">Card Limit: </InputLabel>
                            </Grid>
                            <Grid item>
                                <TextField 
                                    value={cardLimit}
                                    name="cardLimit" 
                                    fullWidth
                                    style={{ margin: '8px 8px 8px 0px' }}
                                    margin="normal"
                                    type="number"
                                    disabled
                                    onChange={(e) => setCardLimit(e.target.value)} 
                                />
                            
                            </Grid>
                            
                        </Grid>
                       
                    </Grid>
                    <Divider variant={"fullWidth"} style={{fontSize: '20px', margin: '20px 0px 20px'}}/>
                    <Grid item container direction="row" >
                        <Grid container direction="row" item xs={12} sm={6} alignItems="center" justify="flex-start">
                            <Grid item xs={4}>
                                <InputLabel id="demo-simple-select-label">Card Spend Limit: </InputLabel>
                            </Grid>
                            <Grid item>
                                <TextField 
                                    value={spendLimit} 
                                    name="spendLimit" 
                                    fullWidth
                                    style={{ margin: '8px 8px 8px 0px' }}
                                    margin="normal"
                                    type="number"
                                    onChange={(e) => setSpendLimit(e.target.value)} 
                                />
                            
                            </Grid>
                            
                        </Grid>
                       
                    </Grid>
                    <Divider variant={"fullWidth"} style={{fontSize: '20px', margin: '20px 0px 20px'}}/>
                </Container>
            </div>
            <PasswordConfirmationDialog open={openPassword} setOpen={setOpenPassword} onFinish={() => console.log('passwworrrd')}/>
            <BottomToolbar btnRejectCard={Cardstatement !== '' ? () => setOpenReject(true) : undefined} btnDel={deleteSource} btnBack={() => history.goBack()} btnSave={updateSource}/>
        </div>
        <RejectApplicationDialog sourceIDReject={id} rejectType={'installment_authorize_rejected'} open={openReject} setOpen={() => setOpenReject(false)} user={query.has('uid') ? query.get('uid') : ownerID} />
        <SuccessDialog openDialog={openSuccess} setOpenDialog={setOpenSuccess} dialogMessage={dialogMsg}/>
        <ErrorDialog openDialog={openErrorDialog} setOpenDialog={setOpenErrorDialog} dialogMessage={error} />
        </>
    )
}

export default SourceDetails;
