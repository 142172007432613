import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import React from "react";


export const DeleteConfirmationDialog: React.FC<{
    open: boolean;
    setOpen: () => void;
    btnYes: () => void;
    btnNo: () => void;
}> = ({btnYes, btnNo, open, setOpen}) => 
    <Dialog open={open} maxWidth={'xs'}  fullWidth onClose={setOpen}>
        <DialogTitle>
            <Typography style={{fontSize: '20px', fontWeight: 'bold'}} color="primary">
                Delete this item?
            </Typography>
        </DialogTitle>
       
        <DialogActions>
            <div className="button-tool" onClick={btnYes}>
                <Typography style={{fontSize: 16, color: 'white'}} >Yes</Typography>
            </div>
            <div className="button-delete" onClick={btnNo}>
                <Typography style={{fontSize: 16, color: 'white'}} >No</Typography>
            </div>
        </DialogActions>
    </Dialog>