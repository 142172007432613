import React, {useEffect, useState} from 'react';
import {
    useHistory
} from 'react-router-dom';
import {
    AppBar,
    Box,
    Card,
    CardContent,
    Container, 
    Table, 
    TableCell, 
    TableBody, 
    TableFooter, 
    TableContainer, 
    TableHead, 
    TableRow,
    TablePagination,
    Toolbar,
    Typography
} from '@material-ui/core';
import {Row} from 'react-bootstrap';
import './style.scss';
import moment from 'moment';
import Auth from '../../auth';
import {
    Button
} from 'reactstrap';
interface Column {
    id: 'email' | 'permission'  ;
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
  }
  
const columns: Column[] = [
    { id: 'email', label: 'Email', minWidth: 50 },
    {
        id: 'permission',
        label: 'Permissions',
        minWidth: 50,
    },
    
];

let test =  [
    {
        "id": 3,
        "first_name": "Testingg",
        "last_name": "Staff",
        "email": "testing@test.com",
        "has_full_access": true
    },
]

const Staffs: React.FC<{
    setLoadingOverlay: (e: boolean) => void;
}> = ({setLoadingOverlay}) => {
    const history = useHistory();
    const [args, setArgs] = useState<any | null>('');
    const [count, setCount] = useState<any | null>(0);
    const [pageSize, setPageSize] = useState<any | null>(10)
    const [staffs, setStaffs] = useState<any| null>(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);


    const handleChangePage = (event: unknown, newPage: number) => {
        if(newPage !== page){
            console.log('PAGE: ',newPage+1)
            setPage(newPage);
            if((newPage+1) === 1){
                
                setArgs(`page=1`);
            }else{
                setArgs(`page=${newPage+1}`)
            }
        }
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPageSize(event.target.value);
        setPage(0);
    };

    useEffect(() => {
        if(staffs === null){
            
            loadStaffs('')
        }
        
    },[staffs])

    useEffect(() => {
        let newArgs = `?page_size=${pageSize}&&`+args
        loadStaffs(newArgs)
    },[args, pageSize])

    const loadStaffs = (params: any) => {
        setLoadingOverlay(true)
        Auth.dashboard.getStaffs(
            params,
            (resp) => {
                console.log(resp)
                console.log(resp.results)
                setLoadingOverlay(false)
                setCount(resp.count)
                setStaffs(resp.results)
            },
            (e) => {
                console.log(e)
                setLoadingOverlay(false)
            }
        )
    }
    return(
        <div className="page-content staffs">
            <Container>
                <Row>
                    <AppBar position="static" style={{ background: 'transparent', boxShadow: 'none'}}>
                    <Toolbar>
                        <Typography style={{fontSize: '20px', fontWeight: 'bold'}} color="primary">
                            Staff
                        </Typography>
                        <Box display='flex' flexGrow={1} />
                        <div className="button-tool" onClick={()=> history.push('/admin/staff/create')}>
                            <Typography style={{fontSize: 16, color: 'white'}}>Create Staff</Typography>
                        </div>
                    </Toolbar>
                    </AppBar>
                </Row>
                <Row>
                    <Card>
                        <CardContent>

                       
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                    {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ maxWidth: column.minWidth }}
                                            >
                                            <Typography style={{fontSize: '16px', fontWeight: 'bold'}} color="primary">
                                                {column.label}
                                                </Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {staffs && staffs.map((obj: any) => {
                                        console.log(obj)
                                        return (
                                            
                                            <TableRow className="staff-row" onClick={() => history.push(`/admin/staff/view/${obj.id}`)}>
                                                <TableCell>
                                                    {obj.email}
                                                </TableCell>
                                                
                                                
                                                <TableCell>
                                                    {obj.has_full_access ? 'Full Access' : 'No Access'}
                                                </TableCell>
                                                
                                            </TableRow>
                                            
                                        )
                                    })}
                                    
                                </TableBody>
                                <TableFooter>
                                    
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        count={test.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </TableFooter>
                            </Table>
                        </TableContainer>
                        </CardContent>
                    </Card>
                </Row>
            </Container>
        </div>
    )
}
export default Staffs;