import React, { useState, useEffect, useContext } from 'react';
import {
  Container,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Divider,
  Typography,
  Card,
  CardContent,
  MenuItem,
  Select,
  InputLabel,
  makeStyles,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
} from '@material-ui/core';
import './style.scss';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Check from '@material-ui/icons/Check';
import { BottomToolbar } from '../../components';
import { Row, Col } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { ParamTypes } from '../../interface';
import Auth from '../../auth';
import moment from 'moment';
import { ErrorDialog } from '../../components/ErrorDialog';
import { AdminUser } from '../../models';

let test = {
  id: 3,
  name: 'Testingg',
  created: '2021-03-12T07:52:24.093247Z',
  modified: '2021-03-12T07:52:24.093263Z',
  end_date: '2021-03-12T07:52:24.093247Z',
  description: '1',
  reference: '1dejg374',
  tag: null,
  merchant: 2,
  buyer: 1,
  amount: '1.00',
  terms: 1,
  transaction_fee: '0.00',
  monthly: '0.00',
  interest_charge: '0.00',
  interest: 0,
  status: 'COMPLETE',
  completed_payments: '0/1',
  category: 'STRAIGHT',
  transaction_type: 'BUY',
};
const orderCancellationReasons = [
  {
    value: 'CHANGE_DELIVERY_ADDRESS',
    label: 'Change delivery address',
  },
  {
    value: 'CHANGE_PAYMENT_METHOD',
    label: 'Change payment method',
  },
  {
    value: 'DECIDED_FOR_ALTERNATIVE_PRODUCT',
    label: 'Decided for alternative product',
  },
  {
    value: 'OTHERS',
    label: 'Others',
  },
];

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#f36d22',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#f36d22',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    fill: '#f47223',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#f47223',
    fill: '#f47223',
  },
  circle: {
    width: 8,
    fill: '#f47223',
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#f47223',
    fill: '#f47223',
    zIndex: 1,
    fontSize: 18,
  },
  vertical: {
    flexDirection: 'column',
  },
});

function QontoStepIcon(props: any) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <Check className={classes.completed} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const TransactionDetails: React.FC<{
  setLoadingOverlay: (e: boolean) => void;
}> = ({ setLoadingOverlay }) => {
  const classes = useQontoStepIconStyles();
  const { id } = useParams<ParamTypes>();
  const { admin, setAdmin } = useContext(AdminUser.Context);
  const [users, setUsers] = useState<any | null>(null);
  const [name, setName] = useState<string | null>('');
  const [activeStep, setActiveState] = React.useState(0);
  const [desc, setDesc] = useState<string | null>('');
  const [merchant, setMerchant] = useState<any | null>(null);
  const [buyer, setBuyer] = useState<any | null>(null);
  const [amount, setAmount] = useState<any | null>('');
  const [monthly, setMonthly] = useState<any | null>('');
  const [transact_fee, setTransactFee] = useState<any | null>('');
  const [interest, setInterest] = useState<any | null>('');
  const [interest_charge, setInterestCharge] = useState<any | null>('');
  const [terms, setTerms] = useState<any | null>('');
  const [status, setStatus] = useState<any | null>('');
  const [category, setCategory] = useState<any | null>('');
  const [paymentType, setPaymentType] = useState<any | null>('');
  const [updatedPaymentStatus, setUpdatedPaymentStatus] = useState<string>('');
  const [isShoulderedByMerchant, setIsShoulderedByMerchant] =
    useState<boolean>(false);
  const [transaction_type, setTransactionType] = useState<any | null>('');
  const [completed_payments, setCompletedPayments] = useState<any | null>('');
  const [transactionHistory, setTransactionHistory] = useState<any | null>(
    null
  );
  const [reference, setReference] = useState<any | null>(null);
  const [activities, setActivities] = useState<any | null>(null);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [selectedPaymentID, setSelectedPaymentID] = useState<number>(0);
  const [error, setError] = useState('');
  const history = useHistory();

  useEffect(() => {
    if (id && !reference) {
      setLoadingOverlay(true);
      loadTransaction();
    }
  }, [id, reference]);

  const loadTransaction = () => {
    Auth.dashboard.getTransactionsDetails(
      id,
      (resp) => {
        setReference(resp.reference);
        setName(resp.name);
        setAmount(resp.amount);
        setDesc(resp.description);
        setBuyer(resp.buyer_email);
        setMerchant(resp.merchant_email);
        setMonthly(resp.monthly);
        setTransactFee(resp.transaction_fee);
        setInterest(resp.interest);
        setInterestCharge(resp.interest_charge);
        setTerms(resp.terms);
        setStatus(resp.status);
        setCategory(resp.category_name);
        setPaymentType(resp.payment_type_name);
        setTransactionType(resp.transaction_type);
        setCompletedPayments(resp.completed_payments);

        setTransactionHistory(resp.payments);
        setActivities(resp.activities);
        setLoadingOverlay(false);
      },
      (e) => {
        setError(e.error ? e.error : e.detail + ' Redirecting you...');
        setOpenErrorDialog(true);
        setTimeout(function () {
          history.push('/admin/transactions');
        }, 2000);
      }
    );
  };

  const updatePaymentStatus = (payment_id: any, status: string) => {
    setOpenConfirmationDialog(false);
    setLoadingOverlay(true);
    Auth.dashboard.patchPaymentStatus(
      payment_id,
      status,
      (resp) => {
        loadTransaction();
      },
      (e) => {
        setLoadingOverlay(false);
      }
    );
  };

  const updateTransaction = () => {
    setLoadingOverlay(true);
    Auth.dashboard.editTransactionDetails(
      id,
      name,
      desc,
      merchant,
      buyer,
      amount,
      monthly,
      transact_fee,
      interest,
      interest_charge,
      terms,
      status,
      category,
      transaction_type,
      completed_payments,
      (resp) => {
        setLoadingOverlay(false);
      },
      (e) => {
        setLoadingOverlay(false);
      }
    );
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };

  const handleOpenConfirmationDialog = (status: string, paymentID: number) => {
    setUpdatedPaymentStatus(status);
    setOpenConfirmationDialog(true);
    setSelectedPaymentID(paymentID);
  };

  return (
    <div className="page-content transaction-details">
      <div className="transaction-details-container">
        <Container>
          <Typography color="primary" style={{ fontSize: 16, padding: 15 }}>
            Reference: {reference}
          </Typography>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="center"
            xs={12}
            sm={12}
            spacing={5}
          >
            <Grid container direction="column" spacing={2} xs={12} sm={7} item>
              <Card>
                <CardContent>
                  <Grid item style={{ paddingBottom: '20px' }}>
                    <Typography color="primary" style={{ fontSize: 16 }}>
                      Transaction Details:{' '}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    style={{ padding: '10px 0px' }}
                    alignItems="center"
                    justify="space-between"
                  >
                    <Grid item>
                      <Typography color="primary" style={{ fontSize: 16 }}>
                        Name:{' '}
                      </Typography>
                    </Grid>
                    <Grid item>{name}</Grid>
                  </Grid>
                  <div
                    style={{
                      paddingTop: '20px',
                      paddingBottom: '20px',
                    }}
                  >
                    <Divider />
                  </div>
                  <Grid container item spacing={2} direction="row">
                    <Grid
                      container
                      xs={12}
                      spacing={2}
                      item
                      alignItems="center"
                      justify="space-between"
                      direction="row"
                    >
                      <Grid item>
                        <Typography color="primary" style={{ fontSize: 16 }}>
                          Status:{' '}
                        </Typography>
                      </Grid>
                      <Grid item>{status}</Grid>
                    </Grid>
                  </Grid>
                  <div
                    style={{
                      paddingTop: '20px',
                      paddingBottom: '20px',
                    }}
                  >
                    <Divider />
                  </div>
                  <Grid
                    container
                    style={{ padding: '10px 0px' }}
                    alignItems="center"
                    justify="space-between"
                  >
                    <Grid item>
                      <Typography color="primary" style={{ fontSize: 16 }}>
                        Description:{' '}
                      </Typography>
                    </Grid>
                    <Grid item>{desc}</Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid container direction="column" spacing={2} xs={12} sm={5} item>
              <Card>
                <CardContent>
                  <Grid container alignItems="center" justify="space-between">
                    <Grid item>
                      <Typography color="primary" style={{ fontSize: 16 }}>
                        Merchant:{' '}
                      </Typography>
                    </Grid>
                    <Grid item>{merchant}</Grid>
                  </Grid>
                  <div
                    style={{
                      paddingTop: '20px',
                      paddingBottom: '20px',
                    }}
                  >
                    <Divider />
                  </div>
                  <Grid container alignItems="center" justify="space-between">
                    <Grid item>
                      <Typography color="primary" style={{ fontSize: 16 }}>
                        Buyer:{' '}
                      </Typography>
                    </Grid>
                    <Grid item>{buyer}</Grid>
                  </Grid>
                  <div
                    style={{
                      paddingTop: '20px',
                      paddingBottom: '20px',
                    }}
                  >
                    <Divider />
                  </div>

                  <Grid container item spacing={2} direction="row">
                    <Grid
                      container
                      xs={12}
                      spacing={2}
                      item
                      alignItems="center"
                      justify="space-between"
                      direction="row"
                    >
                      <Grid item>
                        <Typography color="primary" style={{ fontSize: 16 }}>
                          Status:{' '}
                        </Typography>
                      </Grid>
                      <Grid item>{status}</Grid>
                    </Grid>
                  </Grid>
                  <div
                    style={{
                      paddingTop: '20px',
                      paddingBottom: '20px',
                    }}
                  >
                    <Divider />
                  </div>

                  <Grid container item spacing={1} direction="row">
                    <Grid container xs={6} spacing={2} item direction="column">
                      <Grid item>
                        <Typography color="primary" style={{ fontSize: 16 }}>
                          Category:{' '}
                        </Typography>
                      </Grid>
                      <Grid item>{category}</Grid>
                    </Grid>
                    <Grid container xs={6} spacing={2} item direction="column">
                      <Grid item>
                        <Typography color="primary" style={{ fontSize: 16 }}>
                          Payment Type:{' '}
                        </Typography>
                      </Grid>
                      <Grid item>{paymentType}</Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            xs={12}
            sm={12}
            spacing={5}
          >
            <Grid container direction="column" spacing={2} xs={12} sm={12} item>
              <Card>
                <CardContent>
                  <Grid
                    container
                    item
                    direction="row"
                    xs={12}
                    lg={12}
                    spacing={2}
                    alignItems="center"
                    justify="center"
                  >
                    <Grid item xs={12} lg={4}>
                      <TextField
                        value={amount}
                        name="amount"
                        fullWidth
                        label={<Typography>Amount</Typography>}
                        style={{
                          margin: '8px 8px 8px 0px',
                        }}
                        margin="normal"
                        variant="outlined"
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <TextField
                        value={terms}
                        name="terms"
                        fullWidth
                        type="number"
                        label={<Typography>Terms</Typography>}
                        style={{
                          margin: '8px 8px 8px 0px',
                        }}
                        margin="normal"
                        variant="outlined"
                        onChange={(e) => setTerms(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <TextField
                        value={monthly}
                        name="monthly"
                        fullWidth
                        label={<Typography>Monthly</Typography>}
                        style={{
                          margin: '8px 8px 8px 0px',
                        }}
                        margin="normal"
                        variant="outlined"
                        onChange={(e) => setMonthly(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <TextField
                        value={transact_fee}
                        name="transact_fee"
                        fullWidth
                        label={<Typography>Transaction Fee</Typography>}
                        style={{
                          margin: '8px 8px 8px 0px',
                        }}
                        margin="normal"
                        variant="outlined"
                        onChange={(e) => setTransactFee(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <TextField
                        value={interest}
                        name="interest"
                        fullWidth
                        label={<Typography>Interest</Typography>}
                        style={{
                          margin: '8px 8px 8px 0px',
                        }}
                        margin="normal"
                        variant="outlined"
                        onChange={(e) => setInterest(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <TextField
                        value={interest_charge}
                        name="interest_charge"
                        fullWidth
                        label={<Typography>Interest Charge</Typography>}
                        style={{
                          margin: '8px 8px 8px 0px',
                        }}
                        margin="normal"
                        variant="outlined"
                        onChange={(e) => setInterestCharge(e.target.value)}
                      />
                    </Grid>

                    <Grid container xs={12} lg={4} item direction="column">
                      <Grid item>
                        <Typography color="primary" style={{ fontSize: 16 }}>
                          Completed Payments:{' '}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <TextField
                          value={completed_payments}
                          name="completed_payments"
                          fullWidth
                          label={<Typography>Completed Payments</Typography>}
                          style={{
                            margin: '8px 8px 8px 0px',
                          }}
                          margin="normal"
                          variant="outlined"
                          onChange={(e) => setCompletedPayments(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            xs={12}
            sm={12}
            spacing={5}
          >
            <Grid container direction="column" spacing={2} xs={12} sm={12} item>
              <Card>
                <CardContent>
                  <Grid item>
                    <Typography color="primary" style={{ fontSize: 16 }}>
                      Transaction History:{' '}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Stepper
                      activeStep={activeStep}
                      connector={<QontoConnector />}
                      orientation="vertical"
                      classes={{
                        vertical: classes.vertical,
                      }}
                    >
                      {transactionHistory &&
                        transactionHistory.map((payment: any) => (
                          <Step key={payment.id}>
                            <StepLabel StepIconComponent={QontoStepIcon}>
                              <Grid
                                container
                                direction="row"
                                alignItems="center"
                                justify="space-between"
                              >
                                <Grid item>
                                  {payment.status} {payment.id}
                                </Grid>

                                <Grid item>
                                  <Typography
                                    style={{
                                      fontSize: 16,
                                      color: '#f47223',
                                    }}
                                    variant="caption"
                                    color="secondary"
                                  >
                                    PHP {payment.amount}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Divider />
                              <Grid
                                container
                                direction="row"
                                alignItems="center"
                                justify="space-between"
                              >
                                <Grid item>
                                  <Typography
                                    style={{
                                      fontSize: 12,
                                      color: 'grey',
                                    }}
                                    variant="caption"
                                    color="secondary"
                                  >
                                    {moment(
                                      payment.interval_date
                                        ? payment.interval_date
                                        : payment.created
                                    ).format('MMMM D, YYYY h:mm A')}
                                  </Typography>
                                </Grid>
                                {admin.has_full_access &&
                                  payment.status !== 'COMPLETE' &&
                                  payment.is_editable && (
                                    <Grid item>
                                      <div
                                        className="button-tool-small"
                                        onClick={() =>
                                          handleOpenConfirmationDialog(
                                            'COMPLETE',
                                            payment.id
                                          )
                                        }
                                      >
                                        <Typography
                                          style={{
                                            fontSize: 16,
                                            color: 'white',
                                          }}
                                        >
                                          Complete
                                        </Typography>
                                      </div>

                                      {admin.has_full_access &&
                                        payment.status !==
                                          'Failed, awaiting for payment of monthly amortization and late penalty fees' && (
                                          <div
                                            className="button-tool-small-penalty"
                                            onClick={() =>
                                              handleOpenConfirmationDialog(
                                                'Failed, awaiting for payment of monthly amortization and late penalty fees',
                                                payment.id
                                              )
                                            }
                                          >
                                            <Typography
                                              style={{
                                                fontSize: 16,
                                                color: 'white',
                                              }}
                                            >
                                              Penalty
                                            </Typography>
                                          </div>
                                        )}
                                    </Grid>
                                  )}
                              </Grid>
                            </StepLabel>
                          </Step>
                        ))}
                    </Stepper>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
      <BottomToolbar btnBack={() => history.goBack()} />
      <ErrorDialog
        openDialog={openErrorDialog}
        setOpenDialog={setOpenErrorDialog}
        dialogMessage={error}
      />
      <Dialog
        open={openConfirmationDialog}
        onClose={handleCloseConfirmationDialog}
      >
        <DialogTitle id="simple-dialog-title">
          Are you sure you want to set the status to "{updatedPaymentStatus}"?
        </DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', gap: 5 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                updatePaymentStatus(selectedPaymentID, updatedPaymentStatus)
              }
            >
              Yes
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCloseConfirmationDialog}
            >
              No
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TransactionDetails;
