import React from 'react'
import Cookies from 'js-cookie';
import { IContextAdmin } from '../interface'
import URL from '../services/url';
import { ApiRequest } from '../services/http';
import Auth from '../auth';


export const AdminUser = {
    Context: React.createContext<IContextAdmin>({
        admin: null,
        setAdmin: () => {},
    }),
    get: (success: (e: any) => void) => {
        console.log("Getting admin info")
        
        ApiRequest.get(URL.USER.PROFILE)
        .then(response => { 
            Auth.dashboard.getStaffDetails(
                response.data.id,
                (resp) => {
                    console.log(resp)
                    let newAdmin = {...response.data, ...resp}
                    console.log(newAdmin)
                    success(newAdmin)
                },
                (e) => {
                    console.log(e)
                }
            )
        })
        .catch((e) => {
            Cookies.remove('admin-key');
            localStorage.removeItem('admin-key')
            console.log(e)
        })
    }
}