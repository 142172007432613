import React, { useState, useEffect } from 'react';
import './style.scss';
import {
    IconPrefix,
    IconName
} from '@fortawesome/fontawesome-svg-core';
import Icon from '../../assets/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface OnChangeType {
    name: string,
    type?: string,
    value?: any,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    className?: string,
    placeholder?: string,
    disabled?: any
    icon?: { prefix: IconPrefix, name: IconName },
    errorMessage?: string,
    pesoIcon?: boolean
    
}

const FGIcon = (prefix: IconPrefix, name: IconName) => 
    <div className='fg-input-icon-container'>
        <FontAwesomeIcon 
            icon={Icon(prefix, name)} 
            className='fg-input-icon'
        />
    </div>

const LoginFGIcon = (prefix: IconPrefix, name: IconName) => 
    <div className='fg-input-icon-container-login'>
        <FontAwesomeIcon 
            icon={Icon(prefix, name)} 
            className='fg-input-icon'
        />
    </div>

export const TextInput: React.FC<OnChangeType> = ({ name, type = 'text', value, onChange, disabled, className, icon, errorMessage, placeholder, pesoIcon }) => 
    <div className="fg-input">
   
      

        <input 
            name={name}
            type={type}
            value={value || ''}
            disabled={disabled || false}
            onChange={onChange} 
            className={`fg-textinput ${className || ''}`} 
            placeholder={placeholder || ''}
        />
        { errorMessage && <p className="errorMessage">{errorMessage}</p> }
    </div>

export const TextContactInput: React.FC<OnChangeType> = ({ name, type = 'text', value, onChange, className, icon, errorMessage, placeholder, pesoIcon }) => 
    <div className="fg-input">
    
       
        <span style={{fontSize: 16, paddingTop: 1}}>+63 </span>
        <input 
            name={name}
            type={type}
            value={value}
            onChange={onChange} 
            maxLength={10}
            className={`fg-textinput ${className || ''}`} 
            placeholder={placeholder || ''}
        />
        { errorMessage && <p className="errorMessage">{errorMessage}</p> }
    </div>

export const TextLoginInput: React.FC<OnChangeType> = ({ name, type = 'text', value, onChange, className, icon, errorMessage, placeholder, pesoIcon }) => 
    <div className="fg-inputlogin" >
       
       

        <input 
            name={name}
            type={type}
            value={value}
            onChange={onChange} 
            style={{color: 'white'}}
            className={`fg-textinputlogin ${className || ''}`} 
            placeholder={placeholder || ''}
        />
        { errorMessage && <p className="errorMessage">{errorMessage}</p> }
    </div>

export const TextContactLoginInput: React.FC<OnChangeType> = ({ name, type = 'text', value, onChange, className, icon, errorMessage, placeholder, pesoIcon }) => 
    <div className="fg-inputlogin" >
        
       
        <span style={{color: 'white', paddingTop: 1}}>+63 </span>
        <input 
            name={name}
            type={type}
            value={value}
            onChange={onChange} 
            maxLength={10}
            style={{color: 'white'}}
            className={`fg-textinputlogin ${className || ''}`} 
            placeholder={placeholder || ''}
        />
        { errorMessage && <p className="errorMessage">{errorMessage}</p> }
    </div>

export const NumberInput: React.FC<OnChangeType> = ({ onChange, className, ...props }) =>
    <input className={`fg-input fg-numberinput ${className || ''}`} {...props} />

export const FileUpload: React.FC<OnChangeType> = ({ name, type = 'file', value, onChange, className, icon, errorMessage }) => {
    const [files, setFiles] = useState<File[]>([]);
    const [fileSizeError, setFileSizeError] = useState(false);

    return(
        <div className="fg-input fg-input-file">
            <input 
                id={name}
                name={name}
                type={type}
                value={value}
                className={`fg-fileupload ${className || ''}`} 
                accept=".jpg,.jpeg,.png,.pdf"
                onChange={e => {
                    console.log(e.target.files);
                    
                    if(e.target.files !== null){
                        console.log(e.target.files[0]);
                        
                    }
                    if(e.target.files && e.target.files.length !== 0) {
                        var FileSize = e.target.files[0].size / 1024 / 1024;
                        if(FileSize > 2){
                            setFileSizeError(true)
                        }else{
                            console.log('selected');
                            let theseFiles = [];
                            let uploadedFiles = Array.from(e.target.files);
                            theseFiles = [...files, ...uploadedFiles];
                            setFiles(theseFiles);
                            onChange(e);
                        }
                       
                    }
                }} 
                multiple
            />

            { files && files.length > 0 &&
                <ul className='uploadList'>
                    { files.map((f, index) =>
                        <li key={`${index}_filelist`}>
                            { (f.type === 'image/png' || f.type === 'image/jpg' || f.type === 'image/jpeg') ?
                                <FontAwesomeIcon 
                                    icon={Icon('far', 'file-image')} 
                                    className='fg-fileupload-fileicon'
                                    size={"2x"}
                                />
                            : f.type === 'image/pdf' ?
                                <FontAwesomeIcon 
                                    icon={Icon('far', 'file-pdf')} 
                                    className='fg-fileupload-fileicon'
                                    size={"2x"}
                                />
                            :
                                <FontAwesomeIcon 
                                    icon={Icon('far', 'file')} 
                                    className='fg-fileupload-fileicon'
                                    size={"2x"}
                                />
                            }
                            <span>
                                {f.name.length > 20 ?
                                    `${f.name.substring(0, 10)}...${f.name.slice(-5)}`
                                    :
                                    f.name
                                }
                            </span>
                            <div className='removeUpload'
                                onClick={()=> {
                                    console.log('removing', f);
                                    const theFile = f;
                                    const theFiles = files;
                                    let newFiles = [];

                                    newFiles = theFiles.filter(tf => tf.name !== theFile.name);
                                    setFiles(newFiles);
                                }}
                            >
                                x
                            </div>
                        </li>
                    )}
                </ul>
            }
            
            <label htmlFor={name}>
                { files.length === 0 ?
                    <>
                        <FontAwesomeIcon 
                            icon={Icon('fas', 'cloud-upload-alt')} 
                            className='fg-fileinput-icon'
                            size={"5x"}
                        />
                        <div className='fg-uploadbutton'>
                            UPLOAD
                        </div>
                    </> :

                    <div className='fg-uploadbutton'>
                        UPLOAD MORE
                    </div>
                }
            </label>
            { fileSizeError && <p className="errorMessage">File must be less than 2mb!</p>}
            { errorMessage && <p className="errorMessage">{errorMessage}</p> }
        </div>
    );
}
