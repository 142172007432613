import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Container,
  Table,
  TableCell,
  TableBody,
  TableFooter,
  Accordion,
  Card,
  CardContent,
  Select,
  Grid,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Toolbar,
  Typography,
  MenuItem,
  AccordionDetails,
  AccordionSummary,
  InputLabel,
  FormControl,
  TextField,
  InputAdornment,
  Divider,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Row } from 'react-bootstrap';

import './style.scss';
import moment from 'moment';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Auth from '../../auth';
import SearchIcon from '@material-ui/icons/Search';
import MyURL from '../../services/url';
import { AdminUser } from '../../models';
import { useQuery } from '@tanstack/react-query';
interface Column {
  id:
    | 'name'
    | 'ref'
    | 'merchant'
    | 'status'
    | 'partner_site'
    | 'date_created'
    | 'date_modified';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'name', label: 'Name', minWidth: 50 },
  { id: 'ref', label: 'Ref', minWidth: 50 },
  {
    id: 'merchant',
    label: 'Merchant',
    minWidth: 50,
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 50,
  },
  {
    id: 'partner_site',
    label: 'Partner Site',
    minWidth: 50,
  },
  {
    id: 'date_created',
    label: 'Created',
    minWidth: 50,
  },
];

const Transactions: React.FC<{
  setLoadingOverlay: (e?: boolean) => void;
}> = ({ setLoadingOverlay }) => {
  const history = useHistory();
  const { admin, setAdmin } = useContext(AdminUser.Context);
  const [args, setArgs] = useState<any | null>('');
  const [count, setCount] = useState<any | null>(0);
  const [transactions, setTransactions] = useState<any | null>(null);
  const [buyerStatus, setBuyerStatus] = useState<any | null>('');
  const [merchantStatus, setMerchantStatus] = useState<any | null>('');
  const [category, setCategory] = useState<any | null>('');
  const [pageSize, setPageSize] = useState<any | null>(10);

  const location = useLocation();

  const [categoryList, setCategoryList] = useState<any | null>(null);
  const [paymentTypeList, setPaymentTypeList] = useState<any | null>(null);

  const [buyer_email, setBuyerEmail] = useState<any | null>('');
  const [merchant_email, setMerchantEmail] = useState<any | null>('');
  const [payment_type, setPaymentType] = useState<any | null>(null);

  const [reference_id, setReferenceID] = useState<any | null>('');

  const [gte, setGte] = useState<any | null>('');
  const [lte, setLte] = useState<any | null>('');

  const [paymentDateStart, setPaymentDateStart] = useState<any | null>('');
  const [paymentDateEnd, setPaymentDateEnd] = useState<any | null>('');

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    if (newPage !== page) {
      console.log('PAGE: ', newPage + 1);
      setPage(newPage);
      if (newPage + 1 === 1) {
        console.log('IS FIRST PAGE');
        setArgs(`page=2`);
      } else {
        console.log('IS NOT FIRST PAGE');
        setArgs(`page=${newPage + 1}`);
      }
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPageSize(event.target.value);
    setPage(0);
  };

  const subscribe = async () => {
    if (admin) {
      let events: any = new EventSource(MyURL.EVENTS.DASHBOARD);
      console.log('SUBSCRIBING');
      events.onmessage = (event: any) => {
        switch (event.type) {
          case 'message':
            const data = JSON.parse(event.data);
            if (
              data.event === 'dashboard_transactions' &&
              data.action === 're-render'
            ) {
              let newArgs =
                `?page_size=${pageSize}` +
                `${page !== 0 ? `&page=${page + 1}` : ''}` +
                `${buyerStatus !== '' ? `&buyer_status=${buyerStatus}` : ''}` +
                `${category !== '' ? `&category=${category}` : ''}` +
                `${
                  payment_type !== null ? `&payment_type=${payment_type}` : ''
                }` +
                `${buyer_email !== '' ? `&buyer=${buyer_email}` : ''}` +
                `${
                  merchant_email !== '' ? `&merchant=${merchant_email}` : ''
                }` +
                `${gte !== '' ? `&gte=${gte}` : ''}` +
                `${lte !== '' ? `&lte=${lte}` : ''}` +
                `${reference_id !== '' ? `&reference=${reference_id}` : ''}` +
                `${
                  paymentDateStart !== '' && paymentDateEnd !== ''
                    ? `&payment_date=${paymentDateStart}to${paymentDateEnd}`
                    : ''
                }` +
                ``;
              loadTransactions(newArgs);
            } else {
              events.close();
            }

            break;
        }
      };

      events.onerror = (e: any) => {
        console.log(e);
        events.close();
        events = null;
      };
      if (!location.pathname.includes('transactions')) {
        console.log('CLOSING');
        events.close();
        events = null;
      }
      return () => {
        events.close();
      };
    }
  };

  useEffect(() => {
    let newArgs =
      `?page_size=${pageSize}` +
      `${page !== 0 ? `&page=${page + 1}` : ''}` +
      `${buyerStatus !== '' ? `&buyer_status=${buyerStatus}` : ''}` +
      `${category !== '' ? `&category=${category}` : ''}` +
      `${payment_type !== null ? `&payment_type=${payment_type}` : ''}` +
      `${buyer_email !== '' ? `&buyer=${buyer_email}` : ''}` +
      `${merchant_email !== '' ? `&merchant=${merchant_email}` : ''}` +
      `${gte !== '' ? `&gte=${gte}` : ''}` +
      `${lte !== '' ? `&lte=${lte}` : ''}` +
      `${reference_id !== '' ? `&reference=${reference_id}` : ''}` +
      `${
        paymentDateStart !== '' && paymentDateEnd !== ''
          ? `&payment_date=${paymentDateStart}to${paymentDateEnd}`
          : ''
      }` +
      ``;
    loadTransactions(newArgs);
  }, [
    page,
    buyerStatus,
    category,
    paymentDateEnd,
    paymentDateStart,
    buyer_email,
    merchant_email,
    payment_type,
    gte,
    lte,
    pageSize,
    reference_id,
  ]);

  useEffect(() => {
    if (transactions === null) {
      setLoadingOverlay(true);
      Auth.dashboard.getPaymentTypes(
        '',
        (resp) => {
          setPaymentTypeList(resp.results);
          Auth.dashboard.getCategory(
            '',
            (response) => {
              setCategoryList(response.results);
            },
            (e) => {
              console.log(e);
            }
          );
        },
        (e) => {
          console.log(e);
        }
      );
      loadTransactions('');
      subscribe();
    }
  }, [transactions]);

  const loadTransactions = (params: any) => {
    setLoadingOverlay(true);
    Auth.dashboard.getTransactions(
      params,
      (resp) => {
        console.log(resp);
        setLoadingOverlay(false);
        console.log(resp.results);
        setCount(resp.count);
        setTransactions(resp.results);
      },
      (e) => {
        console.log(e);
        setLoadingOverlay(false);
      }
    );
  };

  console.log('transactions', transactions);
  return (
    <div className="page-content transactions">
      <Container>
        <Row>
          <Typography
            style={{ fontSize: '20px', fontWeight: 'bold' }}
            color="primary"
          >
            Transactions
          </Typography>
        </Row>
        <Row>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                color="primary"
                style={{ fontSize: '16px', fontStyle: 'bold' }}
              >
                Filters
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                spacing={2}
                direction="row"
                alignItems="center"
                justify="center"
              >
                <Grid item xs={12} sm={2}>
                  <Typography
                    style={{ fontSize: '16px', fontWeight: 'bold' }}
                    color="primary"
                  >
                    Reference
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <TextField
                    id="input-with-icon-textfield"
                    label="Search Reference ID"
                    fullWidth
                    placeholder="Reference ID"
                    value={reference_id}
                    onChange={(e) => setReferenceID(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography
                    style={{ fontSize: '16px', fontWeight: 'bold' }}
                    color="primary"
                  >
                    Email
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    id="input-with-icon-textfield"
                    label="Search Buyer Email"
                    fullWidth
                    placeholder="Buyer Email"
                    value={buyer_email}
                    onChange={(e) => setBuyerEmail(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    id="input-with-icon-textfield"
                    label="Search Merchant Email"
                    fullWidth
                    placeholder="Merchant Email"
                    value={merchant_email}
                    onChange={(e) => setMerchantEmail(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography
                    style={{ fontSize: '16px', fontWeight: 'bold' }}
                    color="primary"
                  >
                    Transaction Date
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    id="date"
                    label="Start Date"
                    type="date"
                    fullWidth
                    value={gte}
                    onChange={(e: any) => setGte(e.target.value)}
                    InputProps={{
                      inputProps: {
                        max: moment().format('YYYY-MM-DD').toString(),
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    id="date"
                    label="End Date"
                    type="date"
                    fullWidth
                    value={lte}
                    onChange={(e: any) => setLte(e.target.value)}
                    InputProps={{
                      inputProps: {
                        max: moment().format('YYYY-MM-DD').toString(),
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography
                    style={{ fontSize: '16px', fontWeight: 'bold' }}
                    color="primary"
                  >
                    Payment Date
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    id="date"
                    label="Payment Start"
                    type="date"
                    fullWidth
                    value={paymentDateStart}
                    onChange={(e: any) => setPaymentDateStart(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    id="date"
                    label="Payment End"
                    type="date"
                    fullWidth
                    value={paymentDateEnd}
                    onChange={(e: any) => setPaymentDateEnd(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography
                    style={{ fontSize: '16px', fontWeight: 'bold' }}
                    color="primary"
                  >
                    Misc
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl variant="filled">
                    <InputLabel htmlFor="filter-status-select">
                      Status
                    </InputLabel>
                    <Select
                      onChange={(e) => setBuyerStatus(e.target.value)}
                      value={buyerStatus}
                      color="primary"
                      autoWidth
                      fullWidth
                      style={{ minWidth: '140px' }}
                      label="Status"
                      inputProps={{
                        name: 'filter-status-select',
                        id: 'filter-status-select',
                      }}
                    >
                      <MenuItem value="">None</MenuItem>
                      <MenuItem value={'PENDING'}>Pending</MenuItem>
                      <MenuItem value={'CURRENT'}>Current</MenuItem>
                      <MenuItem value={'COMPLETE'}>Complete</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl variant="filled">
                    <InputLabel htmlFor="filter-category-select">
                      Category
                    </InputLabel>
                    <Select
                      onChange={(e) => setCategory(e.target.value)}
                      value={category}
                      color="primary"
                      autoWidth
                      fullWidth
                      style={{ minWidth: '140px' }}
                      label="Category"
                      inputProps={{
                        name: 'filter-category-select',
                        id: 'filter-category-select',
                      }}
                    >
                      <MenuItem value="">None</MenuItem>
                      {categoryList &&
                        categoryList.map((cat: any) => (
                          <MenuItem value={cat.name}>{cat.name}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl variant="filled">
                    <InputLabel htmlFor="filter-category-select">
                      Payment Type
                    </InputLabel>
                    <Select
                      onChange={(e) =>
                        e.target.value === ''
                          ? setPaymentType(null)
                          : setPaymentType(e.target.value)
                      }
                      value={payment_type}
                      color="primary"
                      autoWidth
                      fullWidth
                      style={{ minWidth: '140px' }}
                      label="Payment Type"
                      inputProps={{
                        name: 'filter-category-select',
                        id: 'filter-category-select',
                      }}
                    >
                      <MenuItem value={''}>None</MenuItem>
                      {paymentTypeList &&
                        paymentTypeList.map((cat: any) => (
                          <MenuItem value={cat.name}>{cat.name}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Card>
            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ maxWidth: column.minWidth }}
                        >
                          <Typography
                            style={{ fontSize: '16px', fontWeight: 'bold' }}
                            color="primary"
                          >
                            {column.label}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transactions &&
                      transactions.map((obj: any) => {
                        console.log(obj);
                        return (
                          <TableRow
                            className="transaction-row"
                            onClick={() =>
                              history.push(`/admin/transaction/${obj.id}`)
                            }
                          >
                            <TableCell>{obj.name}</TableCell>
                            <TableCell>{obj.reference}</TableCell>
                            <TableCell>{obj.merchant_name}</TableCell>
                            <TableCell>{obj.buyer_status}</TableCell>
                            <TableCell>
                              {obj.from_partner_merchant ? (
                                <CheckCircleIcon
                                  fontSize="small"
                                  style={{ fill: 'green' }}
                                />
                              ) : (
                                <CancelIcon
                                  fontSize="small"
                                  style={{ fill: 'red' }}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {moment(obj.created).format(
                                'MMMM D, YYYY h:mm A'
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                  <TableFooter>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      count={count}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </TableFooter>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Row>
      </Container>
    </div>
  );
};
export default Transactions;
