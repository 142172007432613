import React, {useState, useEffect} from 'react';
import {
    Container, 
    Checkbox,
    FormControlLabel,
    Grid,
    TextField,
    Divider,
    Typography,
    IconButton,
    Table,
    TableContainer,
    TableCell,
    TableHead,
    TableRow,
    TableBody,
    Card,
    CardContent
} from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import './style.scss';
import {
    useHistory,
    useParams 
} from 'react-router-dom'
import {
    BottomToolbar, PasswordConfirmationDialog
} from '../../components'
import {
    ParamTypes
} from '../../interface';
import Auth from '../../auth';



const PayoutDetails: React.FC<any> = () => {
    const history = useHistory();
    const { id } = useParams<ParamTypes>();
    const [additional, setAdditional] = useState<any | null>('');
    const [category, setCategory] = useState<any | null>('');
    const [created, setCreated] = useState<any | null>('');
    const [interest_charge, setInterestCharge] = useState<any | null>('');
    const [merchant, setMerchant] = useState<any | null>(null);
    const [status, setStatus] = useState('');
    const [transaction, setTransaction] = useState<any | null>(null)
    const fileRef = React.createRef()
    const imgDefault = 'https://reservation.asiwebres.com/v4/App_Themes/images/noimage.png';

    useEffect(() => {
        if(additional === ''){
            console.log(id)
            Auth.dashboard.getPayoutDetails(
                id,
                (resp) => {
                    console.log(resp)
                    setAdditional(resp.additional)
                    setCategory(resp.category);
                    setCreated(resp.created)
                    setInterestCharge(resp.interest_charge);
                    setMerchant(resp.merchant)
                    setStatus(resp.status)
                    setTransaction(resp.transaction)
                    
                },
                (e) => {
                    console.log(e)
                }
            )
        }
        
    })

    const editPayout = () => {
        
    }

    const deleteUser = () => {
        Auth.dashboard.deleteUser(
            id,
            (resp) => {
                history.goBack()
            },
            (e) => {
                console.log(e)
            }
        )
    }

    return (
        <div className="page-content user-details">
            <div className="user-details-container">
            <Container >
                <Card>
                    <CardContent>

                            
                        
                            <Grid item container direction="row" spacing={2}>
                                <Grid container direction="row" item xs={12} sm={3} alignItems="center" justify="center">
                                
                                    <Grid container item>
                        
                                        <TextField 
                                            value={additional} 
                                            name="username" 
                                            label="Username"
                                            fullWidth
                                            style={{ margin: 8 }}
                                            variant="outlined"
                                            margin="normal"
                                            disabled
                                        />
                                
                                    </Grid>
                                    
                                </Grid>
                                <Grid container direction="row" item xs={12} sm={3} alignItems="center" justify="center">
                                    <Grid container item>
                                        <TextField 
                                            name="email"
                                            value={category}
                                            label="Email"
                                            fullWidth
                                            variant="outlined"
                                            style={{ margin: 8 }}
                                            margin="normal"
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider variant={"fullWidth"} style={{fontSize: '20px', margin: '20px 0px 20px'}}/>
                            <Grid item container direction="row" spacing={2}>
                                <Grid container direction="row" item xs={12} sm={3} alignItems="center" justify="center">
                                    <Grid container item>
                                        <TextField 
                                            name="last_name"
                                            value={merchant}
                                            label="Last Name"
                                            variant="outlined"
                                            fullWidth
                                            style={{ margin: 8 }}
                                            margin="normal"
                                        />
                                    </Grid>
                                    
                                </Grid>
                                <Grid container direction="row" item xs={12} sm={3} alignItems="center" justify="center">
                                    <Grid container item>
                                        <TextField 
                                            name="last_name"
                                            value={status}
                                            label="Last Name"
                                            variant="outlined"
                                            fullWidth
                                            style={{ margin: 8 }}
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                               
                            </Grid>
              
                            
                            <Divider variant={"fullWidth"} style={{fontSize: '20px', margin: '20px 0px 20px'}}/>
                            
                    </CardContent>
                </Card>
                </Container>
            </div>
            
            <BottomToolbar  />
        </div>
    )
} 

export default PayoutDetails