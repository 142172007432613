import { AppBar, Container, Toolbar, Typography, Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useContext } from 'react';
import {
  useHistory
} from 'react-router-dom'
import { AdminUser } from '../../models';
import './style.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    button: {
        margin: '0px 10px 0px 10px'
    },
  }),
);

export const BottomToolbar: React.FC<{
    canSave?: boolean;
    canDelete?: boolean;
    btnSaveDisabled?: boolean;
    btnRejectApplication?: () => void;
    btnRejectCard?: () => void;
    btnSave?: () => void;
    btnBack?: () => void;
    btnDel?: () => void;
}> = ({
    canSave,
    canDelete,
    btnSave,
    btnRejectApplication,
    btnRejectCard,
    btnBack,
    btnSaveDisabled,
    btnDel,
}) => {
    const {admin} = useContext(AdminUser.Context)
    const history = useHistory();
    const classes = useStyles();
    return(
      <div style={{paddingTop: '20px'}}>
        <AppBar position="static" className={classes.button} style={{ background: 'transparent', top: 'auto', bottom: 0 }}>
          <Container maxWidth="md">
            <Toolbar>
              {btnDel !== undefined && admin.has_full_access && (
                <Button style={{backgroundColor: '#FF0000'}} onClick={btnDel} variant="contained">
                  Delete
                </Button>
              )

              }
              
              <div className="toolbar">
                {btnRejectApplication !== undefined && admin.has_full_access && (
                  <Button style={{backgroundColor: '#FF0000'}} onClick={btnRejectApplication} variant="contained">
                    Reject Application
                  </Button>
                )}
                {btnRejectCard !== undefined && admin.has_full_access && (
                  <Button style={{backgroundColor: '#FF0000'}} onClick={btnRejectCard} variant="contained">
                    Reject Installment
                  </Button>
                )}
                

                <Button color="secondary" onClick={() => history.goBack()} className={classes.button} variant="contained">
                    Back
                </Button>
                {btnSave !== undefined && (
                  <Button color="primary" onClick={btnSave} disabled={btnSaveDisabled} className={classes.button} variant="contained">
                    Save
                </Button>
                )}
                
              </div>
              
            </Toolbar>
          </Container>
        </AppBar>
      </div>
    )
}
