import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconPrefix, IconName } from '@fortawesome/fontawesome-svg-core';
import Icon from '../../assets/icons';
import logo from '../../assets/images/4giveslogo.png';
import './style.scss';
import nav from './nav';

interface MenuItem {
  title: string;
  url: string;
  iconPrefix: string;
  icon: string;
  theKey: number;
  permission: string;
}

const MenuLink: React.FC<MenuItem> = ({
  title,
  url,
  iconPrefix,
  icon,
  theKey,
  permission,
}) => {
  if (permission) {
    console.log(title, permission);
    return (
      <li key={`menu_list_${theKey}`}>
        <NavLink to={url} className="menuItem">
          <span>{title}</span>
        </NavLink>
      </li>
    );
  } else {
    return null;
  }
};

const SideBar: React.FC<{ data?: any }> = ({ data }) => (
  <div className="sidebar">
    <div>
      <div className="logoContainer">
        <img src={logo} alt="" className="main_logo" />
      </div>

      <ul className="navigation">
        {nav.map((n, index) => (
          <MenuLink
            title={n.title}
            url={n.url}
            iconPrefix={n.iconPrefix}
            icon={n.icon}
            permission={
              n.title === 'Proposals'
                ? data.has_full_access
                : n.title === 'Users'
                ? data.has_full_access || data.manager_users
                : n.title === 'Transactions'
                ? data.has_full_access || data.manage_transactions
                : n.title === 'Payment Schedules'
                ? data.has_full_access || data.manage_transactions
                : n.title === 'Payouts'
                ? data.has_full_access
                : n.title === 'Staff'
                ? data.has_full_access
                : n.title === 'Payment Sources'
                ? data.has_full_access
                : n.title === 'Payment Types'
                ? data.has_full_access
                : n.title === 'Categories'
                ? data.has_full_access
                : n.title === 'Activities'
                ? data.has_full_access
                : n.title == 'Companies'
                ? data.has_full_access
                : n.title === 'Home'
                ? data.has_full_access
                : false
            }
            theKey={index}
            key={`menulinkkey_${index}`}
          />
        ))}
      </ul>
    </div>
  </div>
);

export default SideBar;
