import React, { useState, useCallback, useContext, useEffect } from 'react';
import { Link, useHistory, withRouter, useLocation } from 'react-router-dom';
import { Form, Container } from 'react-bootstrap';
import { TextLoginInput, Button } from '../../components';
import './style.scss';
import Auth from '../../auth';
import { ErrorDialog } from '../../components/ErrorDialog';
import { SuccessDialog } from '../../components/SuccessDialog';
import { useQuery as useCustomQueryHook } from '../../hooks';
import { useMutation } from '@tanstack/react-query';
import URL from '../../services/url';
import axios from 'axios';

interface EmailVerifyInput {
  confirmation_key: string;
  uid: string;
}

const EmailVerify: React.FC<{
  setAdmin: (val: any) => void;
  setLoadingOverlay: (val: boolean) => void;
}> = ({ setLoadingOverlay }) => {
  const history = useHistory();
  const query = useCustomQueryHook();
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [openSuccess, setOpenSuccess] = useState(false);
  const [dialogMsg, setDialogMsg] = useState('');
  const [openErrorDialog, setOpenErrorDialog] = useState(false);

  // const { mutate, error, isError, status, data, isLoading } = useMutation(
  //   ['verifyEmail'],
  //   (data: EmailVerifyInput) => {
  //     return axios.put(URL.USER.VERIFY_EMAIL, data);
  //   },
  //   {
  //     onSuccess: () => {
  //       console.log('SUCCESS');
  //       setDialogMsg('Email Verified');
  //       setOpenSuccess(true);
  //     },
  //   }
  // );

  const { mutate, status } = useMutation(
    ['verifyEmail'],
    async (data: EmailVerifyInput) => {
      const response = await fetch(URL.USER.VERIFY_EMAIL, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        const content = await response.json();
        setDialogMsg(content.msg);
        setOpenErrorDialog(true);
      }

      return response.json();
    },
    {
      onSuccess: () => {
        console.log('SUCCESS');
        setDialogMsg('Email Verified');
        setOpenSuccess(true);
      },
    }
  );

  //return ApiRequest.put(URL.USER.VERIFY_EMAIL, data);

  const submitLogin = () => {
    const uid = query.get('uid');
    const confirmationKey = query.get('confirmation_key');

    if (uid !== null && confirmationKey !== null) {
      mutate({
        confirmation_key: confirmationKey,
        uid,
      });
    }
  };

  const onSuccessDialogClose = () => {
    history.push('');
  };

  return (
    <div className="login">
      <Container>
        <div className="login-container">
          <Form>
            <div className="form-container">
              <TextLoginInput
                name="text"
                type="text"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                placeholder="Verification Code"
                icon={{ prefix: 'far', name: 'envelope' }}
              />
            </div>

            <Button
              onClick={() => {
                console.log('Submiot');
                submitLogin();
              }}
              color={'login-primary'}
              className="login-button fg-button-block form-button"
            >
              Submit
            </Button>
          </Form>
        </div>
      </Container>
      <SuccessDialog
        openDialog={openSuccess}
        setOpenDialog={setOpenSuccess}
        dialogMessage={dialogMsg}
        onClose={onSuccessDialogClose}
      />
      <ErrorDialog
        openDialog={openErrorDialog}
        setOpenDialog={setOpenErrorDialog}
        dialogMessage={dialogMsg}
      />
    </div>
  );
};

export default EmailVerify;
