import React, { useState, useEffect } from 'react';
import {
  Container,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Divider,
  Typography,
  IconButton,
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Card,
  CardContent,
  Button,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import { green } from '@material-ui/core/colors';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import './style.scss';
import { useHistory, useParams } from 'react-router-dom';
import {
  BottomToolbar,
  DeleteConfirmationDialog,
  MoneyLabel,
  PasswordConfirmationDialog,
  RejectApplicationDialog,
  SaveConfirmationDialog,
} from '../../components';
import { ParamTypes } from '../../interface';
import Auth from '../../auth';
import { ErrorDialog } from '../../components/ErrorDialog';
import { SuccessDialog } from '../../components/SuccessDialog';
import moment from 'moment';
import AddCompanyDialog from './AddCompanyDialog';

const UserDetails: React.FC<{
  admin: any;
  setLoadingOverlay: (e: boolean) => void;
}> = ({ admin, setLoadingOverlay }) => {
  const history = useHistory();
  const { id } = useParams<ParamTypes>();
  const [username, setUsername] = useState<any | null>('');
  const [email, setEmail] = useState<any | null>('');
  const [contact, setContact] = useState<any | null>('+63');
  const [first_name, setFirstName] = useState<any | null>('');
  const [last_name, setLastName] = useState<any | null>('');
  const [sellAuthorized, setSellAuthorized] = useState<any | null>(false);
  const [buyAuthorized, setBuyAuthorized] = useState<any | null>(false);
  const [smsVerified, setSmsVerified] = useState<boolean | undefined>(false);
  const [emailConfirmed, setEmailConfirmed] = useState<any | null>(false);
  const [IDFront, setIDFront] = useState<any>(null);
  const [IDBack, setIDBack] = useState<any>(null);
  const [Bankproof, setBankproof] = useState<any>(null);
  const [userSources, setUserSources] = useState<any | null>(null);
  const [userActivities, setUserActivities] = useState<any | null>(null);
  const [userRejections, setUserRejections] = useState<any | null>(null);
  const [userData, setUserData] = useState<any | null>(null);
  const [openPassword, setOpenPassword] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  const fileRef = React.createRef();
  const [delConfirm, setDelConfirm] = useState(false);
  const [saveConfirm, setSaveConfirm] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [dialogMsg, setDialogMsg] = useState('');
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [openAddCompanyDialog, setOpenAddCompanyDialog] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const imgDefault =
    'https://reservation.asiwebres.com/v4/App_Themes/images/noimage.png';

  useEffect(() => {
    if (email === '') {
      setLoadingOverlay(true);
      console.log(id);
      loadUser();
    }
  }, []);

  const loadUser = () => {
    Auth.dashboard.getUserDetails(
      id,
      (resp) => {
        console.log(resp);
        setUsername(resp.username);
        setEmail(resp.email);
        setUserData(resp);
        setFirstName(resp.first_name);
        setLastName(resp.last_name);
        setContact(resp.contact_number);
        setEmailConfirmed(resp.emailConfirmed);
        setBuyAuthorized(resp.buyAuthorized);
        setSmsVerified(resp.sms_verified);
        setSellAuthorized(resp.sellAuthorized);
        setIDFront(resp.IDFront ? resp.IDFront : imgDefault);
        setIDBack(resp.IDBack ? resp.IDBack : imgDefault);
        setBankproof(resp.Bankproof ? resp.Bankproof : imgDefault);
        setUserRejections(resp.rejection_notes);
        Auth.dashboard.getUserPaymentSources(
          id,
          (resp) => {
            setUserSources(resp.results);
            Auth.dashboard.getUserActivities(
              id,
              (resp) => {
                setUserActivities(resp.results);
                setLoadingOverlay(false);
              },
              (e) => {
                console.log(e);
                setLoadingOverlay(false);
                setError(e.error);
                setOpenErrorDialog(true);
              }
            );
          },
          (e) => {
            console.log(e);
            setLoadingOverlay(false);
            setError(e.error);
            setOpenErrorDialog(true);
          }
        );
      },
      (e) => {
        console.log(e);
        setLoadingOverlay(false);
        setError(e.error ? e.error : e.detail + ' Redirecting you...');
        setTimeout(function () {
          history.push('/admin/users');
        }, 2000);
        setOpenErrorDialog(true);
      }
    );
  };

  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    console.log(userRejections);
  }, [userRejections]);

  const editUser = () => {
    setLoadingOverlay(true);
    let newUserObj: any = {};
    if (userData.username !== username) {
      newUserObj.username = username;
    }
    if (userData.first_name !== first_name) {
      newUserObj.first_name = first_name;
    }
    if (userData.last_name !== last_name) {
      newUserObj.last_name = last_name;
    }
    if (userData.email !== email) {
      newUserObj.email = email;
    }
    if (userData.contact !== contact) {
      newUserObj.contact_number = contact;
    }
    if (userData.emailConfirmed !== emailConfirmed) {
      newUserObj.emailConfirmed = emailConfirmed;
    }
    if (userData.sellAuthorized !== sellAuthorized) {
      newUserObj.sellAuthorized = sellAuthorized;
    }
    if (userData.buyAuthorized !== buyAuthorized) {
      newUserObj.buyAuthorized = buyAuthorized;
    }
    console.log(newUserObj);
    Auth.dashboard.editUserDetails(
      id,
      newUserObj,
      (resp) => {
        console.log(resp);
        setOpenSuccess(true);
        setLoadingOverlay(false);
        setDialogMsg('User has been successfully updated!');
      },
      (e) => {
        console.log(e);
        setLoadingOverlay(false);
        setError(e.error);
        setOpenErrorDialog(true);
      }
    );
  };

  const deleteUser = () => {
    setLoadingOverlay(true);
    Auth.dashboard.deleteUser(
      id,
      (resp) => {
        setLoadingOverlay(false);
        history.push('/admin/users');
      },
      (e) => {
        console.log(e);
        setError(e.error);
        setOpenErrorDialog(true);
      }
    );
  };

  function checkIfUpdated() {
    if (userData.username !== username) {
      return true;
    } else if (userData.first_name !== first_name) {
      console.log(userData.first_name);
      return true;
    } else if (userData.last_name !== last_name) {
      return true;
    } else if (userData.email !== email) {
      return true;
    } else if (userData.contact_number !== contact) {
      console.log(userData.contact_number);
      console.log(contact);
      return true;
    } else if (userData.emailConfirmed !== emailConfirmed) {
      return true;
    } else if (userData.sellAuthorized !== sellAuthorized) {
      return true;
    } else if (userData.buyAuthorized !== buyAuthorized) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (userData) {
      console.log(checkIfUpdated());
      setIsUpdated(checkIfUpdated());
    }
  }, [
    userData,
    username,
    first_name,
    last_name,
    email,
    contact,
    emailConfirmed,
    sellAuthorized,
    buyAuthorized,
  ]);

  const handleAddCompany = () => {
    setOpenAddCompanyDialog(true);
  };

  return (
    <div className="page-content user-details">
      <div className="user-details-container">
        <Container>
          <Card>
            <CardContent>
              <Grid item container direction="row" spacing={2}>
                <Grid
                  container
                  direction="row"
                  item
                  xs={12}
                  sm={3}
                  alignItems="center"
                  justify="center"
                >
                  <Grid container item>
                    <TextField
                      value={username}
                      name="username"
                      label="Username"
                      fullWidth
                      style={{ margin: 8 }}
                      variant="outlined"
                      margin="normal"
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  item
                  xs={12}
                  sm={3}
                  alignItems="center"
                  justify="center"
                >
                  <Grid container item>
                    <TextField
                      name="email"
                      value={email}
                      label="Email"
                      fullWidth
                      variant="outlined"
                      style={{ margin: 8 }}
                      margin="normal"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  item
                  xs={12}
                  sm={3}
                  alignItems="center"
                  justify="center"
                >
                  <Grid container item>
                    <div style={{ display: 'flex' }}>
                      <TextField
                        name="contact"
                        value={contact}
                        label="Contact Number"
                        fullWidth
                        variant="outlined"
                        placeholder="e.g +639123456789"
                        style={{ margin: 8 }}
                        margin="normal"
                        onChange={(e) => {
                          if (e.target.value.length < 14) {
                            setContact(e.target.value);
                          }
                        }}
                      />
                      {smsVerified && (
                        <CheckCircleOutlineRoundedIcon
                          style={{ alignSelf: 'center', color: 'green' }}
                        />
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Divider
                variant={'fullWidth'}
                style={{
                  fontSize: '20px',
                  margin: '20px 0px 20px',
                }}
              />
              <Grid item container direction="row" spacing={2}>
                <Grid
                  container
                  direction="row"
                  item
                  xs={12}
                  sm={3}
                  alignItems="center"
                  justify="center"
                >
                  <Grid container item>
                    <TextField
                      name="first_name"
                      value={first_name}
                      label="First Name"
                      fullWidth
                      variant="outlined"
                      style={{ margin: 8 }}
                      margin="normal"
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  item
                  xs={12}
                  sm={3}
                  alignItems="center"
                  justify="center"
                >
                  <Grid container item>
                    <TextField
                      name="last_name"
                      value={last_name}
                      label="Last Name"
                      variant="outlined"
                      fullWidth
                      style={{ margin: 8 }}
                      margin="normal"
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  item
                  xs={12}
                  sm={5}
                  alignItems="center"
                  justify="center"
                >
                  <Grid container item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="emailConfirmed"
                          checked={emailConfirmed}
                          onChange={(e) => {
                            setEmailConfirmed(!emailConfirmed);
                          }}
                        />
                      }
                      label={
                        <Typography style={{ fontSize: '16px' }}>
                          Email Confirmed
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid container item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="sellAuthorized"
                          checked={sellAuthorized}
                          onChange={(e) => {
                            setSellAuthorized(!sellAuthorized);
                          }}
                        />
                      }
                      label={
                        <Typography style={{ fontSize: '16px' }}>
                          Sell Authorized
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid container item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="buyAuthorized"
                          checked={buyAuthorized}
                          onChange={(e) => {
                            setBuyAuthorized(!buyAuthorized);
                          }}
                        />
                      }
                      label={
                        <Typography style={{ fontSize: '16px' }}>
                          Buy Authorized
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              {userData && (
                <>
                  <Divider
                    variant={'fullWidth'}
                    style={{
                      fontSize: '20px',
                      margin: '20px 0px 20px',
                    }}
                  />

                  <Grid item container direction="row">
                    <Grid
                      container
                      direction="row"
                      item
                      xs={6}
                      sm={8}
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <Typography
                          style={{
                            fontSize: '16px',
                            color: '#f47723',
                            fontWeight: 'bold',
                          }}
                        >
                          Address:
                        </Typography>
                      </Grid>
                      {userData.street && userData.region ? (
                        <Grid item>
                          <Typography
                            style={{
                              fontSize: '16px',
                            }}
                          >
                            {userData.first_name} {userData.last_name}
                            <br />
                            {userData.street}
                            <br />
                            {userData.zip_code}, {userData.city},{' '}
                            {userData.barangay}
                            <br />
                            {userData.region}, Philippines
                          </Typography>
                        </Grid>
                      ) : (
                        <div></div>
                      )}
                    </Grid>
                  </Grid>

                  <Divider
                    variant={'fullWidth'}
                    style={{
                      fontSize: '20px',
                      margin: '20px 0px 20px',
                    }}
                  />

                  <Grid item container direction="row" spacing={6}>
                    <Grid
                      item
                      container
                      xs={12}
                      sm={6}
                      direction="row"
                      spacing={2}
                    >
                      <Grid
                        container
                        direction="row"
                        item
                        xs={12}
                        alignItems="center"
                        justify="space-between"
                      >
                        <Grid item>
                          <Typography
                            style={{
                              fontSize: '16px',
                              color: '#f47723',
                              fontWeight: 'bold',
                            }}
                          >
                            Date of Sign up:
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            style={{
                              fontSize: '16px',
                            }}
                          >
                            {moment(userData.created).format(
                              'MMMM D, YYYY h:mm A'
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        item
                        xs={12}
                        alignItems="center"
                        justify="space-between"
                      >
                        <Grid item>
                          <Typography
                            style={{
                              fontSize: '16px',
                              color: '#f47723',
                              fontWeight: 'bold',
                            }}
                          >
                            Date of birth:
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            style={{
                              fontSize: '16px',
                            }}
                          >
                            {moment(userData.date_of_birth).format(
                              'MMMM D, YYYY'
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        item
                        xs={12}
                        alignItems="center"
                        justify="space-between"
                      >
                        <Grid item>
                          <Typography
                            style={{
                              fontSize: '16px',
                              color: '#f47723',
                              fontWeight: 'bold',
                            }}
                          >
                            Failed Transactions:
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            style={{
                              fontSize: '16px',
                            }}
                          >
                            {userData.total_failed_transactions}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        item
                        xs={12}
                        alignItems="center"
                        justify="space-between"
                      >
                        <Grid item>
                          <Typography
                            style={{
                              fontSize: '16px',
                              color: '#f47723',
                              fontWeight: 'bold',
                            }}
                          >
                            Failed Payments:
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            style={{
                              fontSize: '16px',
                            }}
                          >
                            {userData.total_failed_payments}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        item
                        xs={12}
                        alignItems="center"
                        justify="space-between"
                      >
                        <Grid item>
                          <Typography
                            style={{
                              fontSize: '16px',
                              color: '#f47723',
                              fontWeight: 'bold',
                            }}
                          >
                            Buyer Completed Transactions:
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            style={{
                              fontSize: '16px',
                            }}
                          >
                            {userData.total_transactions_completed.buyer}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        item
                        xs={12}
                        alignItems="center"
                        justify="space-between"
                      >
                        <Grid item>
                          <Typography
                            style={{
                              fontSize: '16px',
                              color: '#f47723',
                              fontWeight: 'bold',
                            }}
                          >
                            Merchant Completed Transactions:
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            style={{
                              fontSize: '16px',
                            }}
                          >
                            {userData.total_transactions_completed.merchant}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item container direction="row" sm={6}>
                      <Grid
                        container
                        direction="row"
                        item
                        xs={12}
                        sm={6}
                        alignItems="center"
                        justify="space-between"
                      >
                        <Grid item>
                          <Typography
                            style={{
                              fontSize: '16px',
                              color: '#f47723',
                              fontWeight: 'bold',
                            }}
                          >
                            Late Fees:
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            style={{
                              fontSize: '16px',
                            }}
                          >
                            {userData.total_current_late_payments}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        item
                        xs={12}
                        alignItems="center"
                        justify="space-between"
                      >
                        <Grid item>
                          <Typography
                            style={{
                              fontSize: '16px',
                              color: '#f47723',
                              fontWeight: 'bold',
                            }}
                          >
                            Total Sales:
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            style={{
                              fontSize: '16px',
                            }}
                          >
                            <MoneyLabel
                              isMoney={true}
                              value={
                                userData.total_spend ? userData.total_spend : 0
                              }
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        item
                        xs={12}
                        alignItems="center"
                        justify="space-between"
                      >
                        <Grid item>
                          <Typography
                            style={{
                              fontSize: '16px',
                              color: '#f47723',
                              fontWeight: 'bold',
                            }}
                          >
                            Total Earned:
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            style={{
                              fontSize: '16px',
                            }}
                          >
                            <MoneyLabel
                              isMoney={true}
                              value={
                                userData.total_earned
                                  ? userData.total_earned
                                  : 0
                              }
                            />
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        direction="row"
                        item
                        xs={12}
                        alignItems="center"
                        justify="space-between"
                      >
                        <Grid item>
                          <Typography
                            style={{
                              fontSize: '16px',
                              color: '#f47723',
                              fontWeight: 'bold',
                            }}
                          >
                            Bank:
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            style={{
                              fontSize: '16px',
                            }}
                          >
                            {userData.bankCode}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        direction="row"
                        item
                        xs={12}
                        alignItems="center"
                        justify="space-between"
                      >
                        <Grid item>
                          <Typography
                            style={{
                              fontSize: '16px',
                              color: '#f47723',
                              fontWeight: 'bold',
                            }}
                          >
                            Bank Account Number:
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            style={{
                              fontSize: '16px',
                            }}
                          >
                            {userData.bankAccountNumber}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}

              <Divider
                variant={'fullWidth'}
                style={{
                  fontSize: '20px',
                  margin: '20px 0px 20px',
                }}
              />
              <Grid item container direction="row">
                <Grid
                  container
                  direction="row"
                  item
                  xs={12}
                  sm={12}
                  alignItems="center"
                  justify="space-between"
                >
                  <Grid
                    container
                    direction="column"
                    xs={12}
                    sm={3}
                    item
                    justify="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <Typography style={{ fontSize: '16px' }}>
                        ID Front:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <div className="image-container">
                        <img
                          className="image"
                          src={IDFront ? IDFront : imgDefault}
                        />
                        {/**
                                                 * <div className="image-buttons">
                                                    <IconButton style={{width: '30px', height: '30px'}}>
                                                        <EditIcon fontSize={'small'} style={{fill: 'green'}}/>
                                                    </IconButton>
                                                    <IconButton style={{width: '30px', height: '30px'}}>
                                                        <VisibilityIcon fontSize={'small'} style={{fill: 'blue'}}/>
                                                    </IconButton>
                                                    
                                                </div>
                                                 * 
                                                 */}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    item
                    xs={12}
                    sm={3}
                    justify="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <Typography style={{ fontSize: '16px' }}>
                        ID Back:
                      </Typography>
                    </Grid>

                    <Grid item>
                      <div className="image-container">
                        <img
                          className="image"
                          src={IDBack ? IDBack : imgDefault}
                        />
                        {/***
                                         *  
                                         * <div className="image-buttons">
                                                    <IconButton style={{width: '30px', height: '30px'}}>
                                                        <EditIcon fontSize={'small'} style={{fill: 'green'}}/>
                                                    </IconButton>
                                                    <IconButton style={{width: '30px', height: '30px'}}>
                                                        <VisibilityIcon fontSize={'small'} style={{fill: 'blue'}}/>
                                                    </IconButton>
                                                    
                                                </div>
                                         * 
                                         */}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    item
                    xs={12}
                    sm={3}
                    justify="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <Typography style={{ fontSize: '16px' }}>
                        Bank Proof:
                      </Typography>
                    </Grid>

                    <Grid item>
                      <div className="image-container">
                        <img
                          className="image"
                          src={Bankproof ? Bankproof : imgDefault}
                        />
                        {/**
                                                 * <div className="image-buttons">
                                                    <IconButton style={{width: '30px', height: '30px'}}>
                                                        <EditIcon fontSize={'small'} style={{fill: 'green'}}/>
                                                    </IconButton>
                                                    <IconButton style={{width: '30px', height: '30px'}}>
                                                        <VisibilityIcon fontSize={'small'} style={{fill: 'blue'}}/>
                                                    </IconButton>
                                                    
                                                </div>
                                                 * 
                                                 */}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider
                variant={'fullWidth'}
                style={{
                  fontSize: '20px',
                  margin: '20px 0px 20px',
                }}
              />
              <Grid
                item
                container
                direction="row"
                spacing={5}
                alignItems="flex-start"
                justify="center"
              >
                <Grid
                  container
                  direction="row"
                  item
                  xs={12}
                  alignItems="center"
                  justify="center"
                >
                  <Grid item>
                    <Card>
                      <CardContent>
                        <Typography
                          style={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                          }}
                          color="primary"
                        >
                          Sources
                        </Typography>
                        <TableContainer>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    style={{
                                      fontSize: '16px',
                                      fontWeight: 'bold',
                                    }}
                                    color="primary"
                                  >
                                    Name
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    style={{
                                      fontSize: '16px',
                                      fontWeight: 'bold',
                                    }}
                                    color="primary"
                                  >
                                    Source Type
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    style={{
                                      fontSize: '16px',
                                      fontWeight: 'bold',
                                    }}
                                    color="primary"
                                  >
                                    Installment Authorized
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    style={{
                                      fontSize: '16px',
                                      fontWeight: 'bold',
                                    }}
                                    color="primary"
                                  >
                                    Cutoff
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    style={{
                                      fontSize: '16px',
                                      fontWeight: 'bold',
                                    }}
                                    color="primary"
                                  >
                                    Card Limit
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography
                                    style={{
                                      fontSize: '16px',
                                      fontWeight: 'bold',
                                    }}
                                    color="primary"
                                  >
                                    Spend Limit
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {userSources &&
                                userSources.map((source: any) => {
                                  return (
                                    <TableRow
                                      key={source.id}
                                      className="user-source-row"
                                      onClick={() =>
                                        history.push(
                                          `/admin/source/${source.id}?uid=${id}`
                                        )
                                      }
                                    >
                                      <TableCell>
                                        {source.type + ' - ' + source.name}
                                      </TableCell>
                                      <TableCell>
                                        {source.source_type}
                                      </TableCell>
                                      <TableCell>
                                        {source.installmentAuthorized ? (
                                          <Typography
                                            style={{
                                              fontSize: '16px',
                                              fontWeight: 'bold',
                                              color: 'green',
                                            }}
                                          >
                                            Verified
                                          </Typography>
                                        ) : source.Cardstatement &&
                                          source.cardLimit &&
                                          !source.is_rejected ? (
                                          <Typography
                                            style={{
                                              fontSize: '16px',
                                              fontWeight: 'bold',
                                              color: '#FFA500',
                                            }}
                                          >
                                            Pending
                                          </Typography>
                                        ) : source.Cardstatement &&
                                          source.cardLimit &&
                                          source.is_rejected ? (
                                          <Typography
                                            style={{
                                              fontSize: '16px',
                                              fontWeight: 'bold',
                                              color: 'red',
                                            }}
                                          >
                                            Rejected
                                          </Typography>
                                        ) : (
                                          <Typography
                                            style={{
                                              fontSize: '16px',
                                              fontWeight: 'bold',
                                              color: 'red',
                                            }}
                                          >
                                            N/A
                                          </Typography>
                                        )}
                                      </TableCell>
                                      <TableCell>{source.cardCutoff}</TableCell>
                                      <TableCell>{source.cardLimit}</TableCell>
                                      <TableCell>{source.spendLimit}</TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                            {userSources !== null && userSources.length >= 1 && (
                              <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                style={{
                                  marginTop: '5px',
                                }}
                                onClick={handleAddCompany}
                              >
                                Add Company
                              </Button>
                            )}
                          </Table>
                        </TableContainer>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Divider
                variant={'fullWidth'}
                style={{
                  fontSize: '20px',
                  margin: '20px 0px 20px',
                }}
              />
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justify="center"
              >
                <Grid item>
                  <Card>
                    <CardContent>
                      <TableContainer>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                  }}
                                  color="primary"
                                >
                                  Recent Activities
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                  }}
                                  color="primary"
                                >
                                  Date
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {userActivities &&
                              userActivities.map((activity: any) => {
                                return (
                                  <TableRow key={activity.id}>
                                    <TableCell>{activity.message}</TableCell>
                                    <TableCell>
                                      {moment(activity.created).format(
                                        'MMMM D, YYYY h:mm A'
                                      )}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Divider
                variant={'fullWidth'}
                style={{
                  fontSize: '20px',
                  margin: '20px 0px 20px',
                }}
              />
              <Grid
                item
                container
                direction="row"
                spacing={3}
                alignItems="center"
                justify="center"
              >
                <Grid item>
                  <Card>
                    <CardContent>
                      <Typography
                        style={{
                          fontSize: '16px',
                          fontWeight: 'bold',
                          textAlign: 'center',
                        }}
                        color="primary"
                      >
                        Rejection Notes
                      </Typography>
                      <TableContainer>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                  }}
                                  color="primary"
                                >
                                  Note
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                  }}
                                  color="primary"
                                >
                                  Type
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  style={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                  }}
                                  color="primary"
                                >
                                  Action
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {userRejections &&
                              userRejections.map((reject: any) => {
                                return (
                                  <TableRow key={reject.id}>
                                    <TableCell>{reject.note}</TableCell>
                                    <TableCell>
                                      {reject.type ===
                                      'installment_authorize_rejected'
                                        ? 'Installment Authorization Rejected'
                                        : reject.type ===
                                          'sell_authorize_rejected'
                                        ? 'Sell Authorization Rejected'
                                        : 'Buy Authorization Rejected'}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Divider
                variant={'fullWidth'}
                style={{
                  fontSize: '20px',
                  margin: '20px 0px 20px',
                }}
              />
            </CardContent>
          </Card>
          <BottomToolbar
            btnRejectApplication={
              userData &&
              userData.IDFront &&
              userData.IDBack &&
              userData.Bankproof &&
              !userData.sellAuthorized
                ? () => setOpenReject(true)
                : undefined
            }
            btnDel={() => setDelConfirm(true)}
            btnSave={() => setSaveConfirm(true)}
            btnSaveDisabled={!isUpdated}
          />
        </Container>
      </div>
      <RejectApplicationDialog
        rejectType={'sell_authorize_rejected'}
        loadUser={loadUser}
        open={openReject}
        setOpen={() => setOpenReject(false)}
        user={id}
        source={userSources}
      />
      <PasswordConfirmationDialog
        setError={setError}
        setOpenErrorDialog={setOpenErrorDialog}
        open={openPassword}
        setOpen={setOpenPassword}
        onFinish={() => (saveConfirm ? editUser() : deleteUser())}
      />
      <SaveConfirmationDialog
        open={saveConfirm}
        setOpen={() => setSaveConfirm(false)}
        btnNo={() => setSaveConfirm(false)}
        btnYes={() => setOpenPassword(true)}
      />
      <DeleteConfirmationDialog
        open={delConfirm}
        setOpen={() => setDelConfirm(false)}
        btnNo={() => setDelConfirm(false)}
        btnYes={() => {
          setOpenPassword(true);
        }}
      />
      <SuccessDialog
        openDialog={openSuccess}
        setOpenDialog={setOpenSuccess}
        onClose={() => setSaveConfirm(false)}
        dialogMessage={dialogMsg}
      />
      <ErrorDialog
        openDialog={openErrorDialog}
        setOpenDialog={setOpenErrorDialog}
        dialogMessage={error}
      />
      <AddCompanyDialog
        open={openAddCompanyDialog}
        setOpen={setOpenAddCompanyDialog}
        userID={id}
      />
    </div>
  );
};

export default UserDetails;
