const nav = [
  {
    title: 'Home',
    url: '/admin/home',
    iconPrefix: 'far',
    icon: 'user',
  },
  {
    title: 'Proposals',
    url: '/admin/proposals',
    iconPrefix: 'fas',
    icon: 'key',
  },
  {
    title: 'Users',
    url: '/admin/users',
    imgUrl: '../../../assets/images/create_sale.png',
    iconPrefix: 'fas',
    icon: 'toolbox',
  },
  {
    title: 'Transactions',
    url: '/admin/transactions',
    iconPrefix: 'fas',
    icon: 'key',
  },
  {
    title: 'Payment Schedules',
    url: '/admin/payment-schedules',
    iconPrefix: 'fas',
    icon: 'credit-card',
  },
  {
    title: 'Payment Sources',
    url: '/admin/sources',
    iconPrefix: 'fas',
    icon: 'credit-card',
  },
  {
    title: 'Payouts',
    url: '/admin/payouts',
    iconPrefix: 'fas',
    icon: 'key',
  },
  {
    title: 'Payment Types',
    url: '/admin/payment-types',
    iconPrefix: 'fas',
    icon: 'credit-card',
  },
  {
    title: 'Categories',
    url: '/admin/categories',
    iconPrefix: 'fas',
    icon: 'credit-card',
  },

  {
    title: 'Staff',
    url: '/admin/staffs',
    imgUrl: '../../../assets/images/create_offer.png',
    iconPrefix: 'fas',
    icon: 'toolbox',
  },
  {
    title: 'Companies',
    url: '/admin/companies',
    imgUrl: '../../../assets/images/create_offer.png',
    iconPrefix: 'fas',
    icon: 'toolbox',
  },
];

export default nav;
