import React, { useState } from 'react';

export const useFormFiles = <T>(initialValue: T) => {
    const [files, setValues] = useState<T>(initialValue);
    const toBase64 = (file: any) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result)
        };
        reader.onerror = error => reject(error);
    });

    function dataURLtoFile(dataurl: any, filename: any) {
 
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
    }
    
    const handleFiles = async (event: any) => {
        if(event.target.files !== null){
            console.log(event.target.files[0])
            if(event.persist !== undefined){
                event.persist();
            }
            setValues({
                ...files,
                [event.target.name]: await toBase64(event.target.files[0])
            })
        }
       
    }
    return {
        files,
        handleFiles
    };
}

