import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Card,
    CardContent,
    Container,
    Table,
    TableCell,
    TableBody,
    TableFooter,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Typography,
    AccordionDetails,
    Accordion,
    FormControl,
    AccordionSummary,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    InputAdornment,
} from '@material-ui/core';
import { Row, Col, Tab } from 'react-bootstrap';
import './style.scss';
import moment from 'moment';
import Auth from '../../auth';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import { Button } from '../../components';
import { useQuery } from '@tanstack/react-query';
import URL from '../../services/url';

interface Column {
    id: 'name' | 'reference' | 'status' | 'date_created' | 'date_modified';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
}

const columns: Column[] = [
    { id: 'name', label: 'Email', minWidth: 50 },
    {
        id: 'reference',
        label: 'Company Name',
        minWidth: 50,
    },
    {
        id: 'status',
        label: 'Contact Person',
        minWidth: 50,
    },
    {
        id: 'date_created',
        label: 'Contact Number',
        minWidth: 50,
    },
    {
        id: 'date_modified',
        label: 'Date Joined',
        minWidth: 50,
    },
];

let test = [
    {
        id: 904,
        name: 'Testing Product 4',
        created: '2021-03-17T06:53:14.904172Z',
        modified: '2021-03-17T06:53:14.930748Z',
        end_date: '2021-03-17T06:53:14.904172Z',
        description: '312',
        tag: null,
        merchant: null,
        buyer: 281,
        amount: '123.00',
        terms: 1,
        transaction_fee: '5.12',
        monthly: '128.12',
        interest_charge: '0.00',
        interest: 0,
        status: 'WAITING',
        category: 'STRAIGHT',
        proposal_type: 'BUYING',
    },
];

const Companies: React.FC<{
    setLoadingOverlay: (e?: boolean) => void;
}> = ({ setLoadingOverlay }) => {
    const history = useHistory();
    const [args, setArgs] = useState<any | null>('');
    const [count, setCount] = useState<any | null>(0);
    const [status, setStatus] = useState<any | null>('');
    const [proposal_type, setProposalType] = useState<any | null>('');
    const [pageSize, setPageSize] = useState<any | null>(10);
    const [proposals, setProposals] = useState<any | null>(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [dateStart, setDateStart] = useState<any | null>('');
    const [dateEnd, setDateEnd] = useState<any | null>('');

    const [reference, setReference] = useState<any | null>('');
    const { data, isLoading } = useQuery(['companiesData'], async () => {
        const response = await (
            await fetch(URL.DASHBOARD.COMPANIES, {
                headers: new Headers({
                    Authorization: `Token ${Auth.key()}`,
                }),
            })
        ).json();
        return response;
    });

    const handleChangePage = (event: unknown, newPage: number) => {
        if (newPage !== page) {
            console.log('PAGE: ', newPage + 1);
            setPage(newPage);
            if (newPage + 1 === 1) {
                setArgs(`page=1`);
            } else {
                setArgs(`page=${newPage + 1}`);
            }
        }
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);
        setPageSize(event.target.value);
        setPage(0);
    };

    useEffect(() => {
        if (proposals === null) {
            loadProposals('');
        }
    }, [proposals]);

    useEffect(() => {
        let newArgs = `?page_size=${pageSize}${args !== '' ? `&${args}` : ''}${
            status !== '' ? `&status=${status}` : ''
        }${proposal_type !== '' ? `&proposal_type=${proposal_type}` : ''}${
            reference !== '' ? `&reference=${reference}` : ''
        }${dateStart !== '' ? `&gte=${dateStart}` : ''}${
            dateEnd !== '' ? `&lte=${dateEnd}` : ''
        }`;
        loadProposals(newArgs);
    }, [args, pageSize, status, proposal_type, reference, dateStart, dateEnd]);

    const loadProposals = (params: any) => {
        setLoadingOverlay(true);
        Auth.dashboard.getProposals(
            params,
            (resp) => {
                console.log(resp);
                console.log(resp.results);
                setCount(resp.count);
                setLoadingOverlay(false);
                setProposals(resp.results);
            },
            (e) => {
                setLoadingOverlay(false);
                console.log(e);
            }
        );
    };

    if (isLoading) return <div></div>;

    console.log('COMPANIES', data);

    return (
        <div className="page-content proposals">
            <Container>
                <Row>
                    <Typography
                        style={{ fontSize: '20px', fontWeight: 'bold' }}
                        color="primary"
                    >
                        Companies
                    </Typography>
                </Row>
                <Row>
                    <Button
                        onClick={() => history.push(`/admin/companies/create`)}
                        color="primary"
                        className=""
                    >
                        Add Company
                    </Button>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography
                                color="primary"
                                style={{ fontSize: '16px', fontStyle: 'bold' }}
                            >
                                Filters
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid
                                container
                                spacing={2}
                                direction="row"
                                alignItems="center"
                                justify="flex-start"
                            >
                                <Grid item xs={12} sm={2}>
                                    <Typography
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                        }}
                                        color="primary"
                                    >
                                        Reference
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <TextField
                                        id="input-with-icon-textfield"
                                        label="Search Reference ID"
                                        fullWidth
                                        placeholder="Reference ID"
                                        value={reference}
                                        onChange={(e: any) =>
                                            setReference(e.target.value)
                                        }
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <Typography
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                        }}
                                        color="primary"
                                    >
                                        Date
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <TextField
                                        id="date"
                                        label="Start Date"
                                        type="date"
                                        fullWidth
                                        value={dateStart}
                                        onChange={(e: any) =>
                                            setDateStart(e.target.value)
                                        }
                                        InputProps={{
                                            inputProps: {
                                                max: moment()
                                                    .format('YYYY-MM-DD')
                                                    .toString(),
                                            },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <TextField
                                        id="date"
                                        label="Payment End"
                                        type="date"
                                        fullWidth
                                        value={dateEnd}
                                        onChange={(e: any) =>
                                            setDateEnd(e.target.value)
                                        }
                                        InputProps={{
                                            inputProps: {
                                                max: moment()
                                                    .format('YYYY-MM-DD')
                                                    .toString(),
                                            },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <Typography
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                        }}
                                        color="primary"
                                    >
                                        Misc
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControl variant="filled">
                                        <InputLabel htmlFor="filter-status-select">
                                            Status
                                        </InputLabel>
                                        <Select
                                            onChange={(e) =>
                                                setStatus(e.target.value)
                                            }
                                            value={status}
                                            variant="outlined"
                                            color="primary"
                                            autoWidth
                                            fullWidth
                                            style={{ minWidth: '140px' }}
                                            label="Status"
                                            inputProps={{
                                                name: 'filter-status-select',
                                                id: 'filter-status-select',
                                            }}
                                        >
                                            <MenuItem value="">None</MenuItem>
                                            <MenuItem value={'ACCEPTED'}>
                                                Accepted
                                            </MenuItem>
                                            <MenuItem value={'WAITING'}>
                                                Waiting
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <FormControl variant="filled">
                                        <InputLabel htmlFor="filter-category-select">
                                            Proposal Type
                                        </InputLabel>
                                        <Select
                                            onChange={(e) =>
                                                setProposalType(e.target.value)
                                            }
                                            value={proposal_type}
                                            variant="outlined"
                                            color="primary"
                                            autoWidth
                                            fullWidth
                                            style={{ minWidth: '190px' }}
                                            label="Category"
                                            inputProps={{
                                                name: 'filter-category-select',
                                                id: 'filter-category-select',
                                            }}
                                        >
                                            <MenuItem value="">None</MenuItem>
                                            <MenuItem value={'BUYING'}>
                                                Buying
                                            </MenuItem>
                                            <MenuItem value={'SELLING'}>
                                                Selling
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Card>
                        <CardContent>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{
                                                        maxWidth:
                                                            column.minWidth,
                                                    }}
                                                >
                                                    <Typography
                                                        style={{
                                                            fontSize: '16px',
                                                            fontWeight: 'bold',
                                                        }}
                                                        color="primary"
                                                    >
                                                        {column.label}
                                                    </Typography>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.results.map((company: any) => {
                                            return (
                                                <TableRow
                                                    className="proposal-row"
                                                    onClick={() =>
                                                        history.push(
                                                            `/admin/companies/edit/${company.id}`
                                                        )
                                                    }
                                                >
                                                    <TableCell>
                                                        {company.email}
                                                    </TableCell>
                                                    <TableCell>
                                                        {company.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {company.contact_person}
                                                    </TableCell>
                                                    <TableCell>
                                                        {company.contact_number}
                                                    </TableCell>
                                                    <TableCell>
                                                        {moment(
                                                            company.created
                                                        ).format(
                                                            'MMMM D, YYYY h:mm A'
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                    <TableFooter>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 25, 100]}
                                            count={count}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={
                                                handleChangeRowsPerPage
                                            }
                                        />
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Row>
            </Container>
        </div>
    );
};

export default Companies;
