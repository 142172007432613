import React, { useState, useEffect, useContext } from 'react';
import {
  Dialog,
  DialogTitle,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Container,
  DialogActions,
} from '@material-ui/core';
import { Button } from '..';

import close from '../../assets/images/close.png';

export const ErrorDialog: React.FC<{
  openDialog: boolean;
  setOpenDialog: (val: boolean) => void;
  dialogMessage: string | null;
  onProceed?: () => void;
  onBack?: () => void;
}> = ({ openDialog, setOpenDialog, dialogMessage, onProceed, onBack }) => (
  <Dialog
    open={openDialog}
    fullWidth={true}
    maxWidth={'xs'}
    onClose={() => {
      setOpenDialog(false);
    }}
    PaperProps={{
      style: {
        backgroundColor: '#fee2b3',
        border: '1px solid #f47223',
        borderRadius: '20px',
      },
    }}
  >
    <DialogTitle style={{ color: 'green', textAlign: 'center' }}>
      <img
        src={close}
        style={{
          cursor: 'pointer',
          position: 'absolute',
          right: '24px',
          marginTop: '5px',
          width: '20px',
        }}
        onClick={() => setOpenDialog(false)}
      />

      <div style={{ paddingTop: 10, color: '#f47223' }}>Error</div>
    </DialogTitle>
    <Container>
      <div
        style={{
          margin: '5px 25px 25px 25px',
          backgroundColor: 'white',
          border: '1px solid white',
          borderRadius: '20px',
        }}
      >
        <div style={{ padding: 20, textAlign: 'center' }}>
          <Grid
            container
            item
            direction="row"
            alignContent="center"
            justify="center"
          >
            <Grid item>{dialogMessage}</Grid>
          </Grid>
        </div>
      </div>

      {onProceed !== undefined && onBack !== undefined && (
        <div style={{ margin: '10px' }}>
          <Grid container justify="center" alignItems="center">
            <Grid item sm={6} xs={12}>
              <Button
                color="login-primary"
                onClick={onBack}
                className="create-sale-button"
              >
                Back to Merchant site
              </Button>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Button
                color="primary"
                onClick={onProceed}
                className="create-sale-button"
              >
                Proceed to Activation Page
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    </Container>
  </Dialog>
);
