import React, { useState, useMemo } from 'react';
import logo from './logo.svg';
import './App.css';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import { routes, PrivateRoute, PublicRoute } from './routes';
import { AdminUser } from './models';
import './assets/styles/global.scss';
import Admin from './layout/Admin';
import { makeStyles, Theme, createStyles, Backdrop } from '@material-ui/core';
import Login from './pages/Login';
import BounceLoader from 'react-spinners/BounceLoader';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import EmailVerify from './pages/EmailVerify';

const THEME = createMuiTheme({
  palette: {
    secondary: {
      main: 'rgb(18, 107, 96)',
    },
    primary: {
      main: '#f47223',
    },
    error: {
      main: '#FF0000',
    },
  },
  overrides: {
    MuiButton: {
      label: {
        color: '#f1f1f1',
      },
    },
  },
  typography: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 16,
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 100000,
      color: '#fff',
    },
  })
);

const App = () => {
  const history = createBrowserHistory();
  const classes = useStyles();
  const [admin, setAdmin] = useState<any | null>(null);
  const adminUserValue = useMemo(
    () => ({ admin, setAdmin }),
    [admin, setAdmin]
  );
  const [loadingOverlay, setLoadingOverlay] = useState(false);

  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={THEME}>
        <AdminUser.Context.Provider value={adminUserValue}>
          {loadingOverlay ? (
            <Backdrop className={classes.backdrop} open={loadingOverlay}>
              <BounceLoader color={'#f47223'} />
            </Backdrop>
          ) : null}
          <Switch>
            <Route
              exact
              path="/"
              component={(props: any) => (
                <Login
                  setAdmin={setAdmin}
                  setLoadingOverlay={setLoadingOverlay}
                />
              )}
            />
            <Route
              exact
              path="/verify"
              component={(props: any) => (
                <EmailVerify
                  setAdmin={setAdmin}
                  setLoadingOverlay={setLoadingOverlay}
                />
              )}
            />
            <PrivateRoute
              path="/admin"
              setLoadingOverlay={setLoadingOverlay}
              admin={adminUserValue}
              component={Admin}
            />
            {/*routes.public.map(page => {
                <PublicRoute key={page.name} setAdmin={setAdmin} setLoadingOverlay={setLoadingOverlay} path={page.url} component={page.component} />
              })*/}
          </Switch>
        </AdminUser.Context.Provider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
