import * as React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router';
import Auth from '../auth';

const PrivateRoute: React.FC<RouteProps | any> =
  ({ component: Component, ...rest }) => {
    
    if (!Auth.isAuthenticated() || !Component) {
        return (<Redirect to='/' />);
    }
    

    return (
        <Route 
            {...rest}
            render={(props:RouteComponentProps<{}>) => <Component {...props} />}
        />
    )
  }

export default PrivateRoute;