import { Container, Dialog, DialogTitle, DialogContent, TextField, Grid } from '@material-ui/core';
import React, {useContext, useState, useEffect} from 'react';
import Auth from '../../auth';
import { AdminUser } from "../../models"
import { Button } from '../Button';
import { TextInput } from '../Input';
import close from '../../assets/images/close.png';

export const PasswordConfirmationDialog: React.FC<{
    open: boolean;
    setOpen: (val: boolean) => void;
    onFinish: () => void;
    setError?: (e: any) => void;
    setOpenErrorDialog?: (e: boolean) => void;
}> = ({open, setOpen, onFinish, setOpenErrorDialog, setError}) => {
    const {admin} = useContext(AdminUser.Context)
    const [password, setPassword] = useState('')
    
    useEffect(() => {
        setPassword('')
    },[open])

    const verifyPass = () => {
        Auth.dashboard.passwordConfirm(
            admin.email,
            password,
            (resp) => {
                console.log(resp)
                setOpen(false)
                onFinish()
            },
            (e)=>{
                if(setOpenErrorDialog !== undefined && setError !== undefined){
                    setOpenErrorDialog(true)
                    setError(
                        e.error 
                        ?
                            e.error 
                        :
                        e.password 
                        ? 
                            e.password
                        :
                        "An error has occured"
                    )
                }
                console.log(e)
            }
        )
    }
 
    return (
        <Dialog 
            open={open} 
            fullWidth={true} 
            maxWidth={'xs'}  
            onClose={() => setOpen(false)}
        >   
            <DialogTitle>
                Password Confirmation
                <img
                    src={close}
                    style={{cursor:'pointer', position: 'absolute', right: '24px', marginTop: '5px', width: '20px'}}
                    onClick={() =>{setOpen(false); 
                        
                    }} 
                />
            </DialogTitle>
            <DialogContent>
                <Container>
                    <Grid container item direction="column" spacing={2} alignItems="center" justify="center">
                        <Grid item>
                            <TextField 
                                type="password"
                                name="password"
                                fullWidth
                                variant="outlined"
                                placeholder="Enter password.."
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                            />
                        </Grid>
                        <Grid item>
                            <Button color="primary" onClick={verifyPass}>
                                Confirm
                            </Button>
                        </Grid>
                        
                    </Grid>
                </Container>
            </DialogContent>
        </Dialog>
    )
}
