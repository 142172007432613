import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from '@material-ui/core';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import CompaniesList from '../../components/CompaniesList';
import { ApiRequest } from '../../services/http';
import URL from '../../services/url';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userID: number;
};

interface AddCompanyInput {
  years_on_company: number;
  salary: number;
  company_id: number;
  user_id: number;
}

const AddCompanyDialog: React.FC<Props> = ({
  open,
  setOpen,
  userID,
}: Props) => {
  const [selectedCompanyID, setSelectedCompanyID] = useState(0);
  const [yearsOnCompany, setYearsOnCompany] = useState(0);
  const [salary, setSalary] = useState(0);

  const { mutate, isLoading } = useMutation(
    (input: AddCompanyInput) => {
      return ApiRequest.post(URL.DASHBOARD.EMPLOYEE_ADD_COMPANY, input);
    },
    {
      onSuccess: () => {
        window.location.reload();
      },
    }
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddCompany = () => {
    mutate({
      years_on_company: yearsOnCompany,
      salary,
      company_id: selectedCompanyID,
      user_id: userID,
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add Company</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gridGap={10}>
          <Box display="flex" gridGap={10} pr={5}>
            <CompaniesList
              setSelectedCompanyID={setSelectedCompanyID}
              variant="outlined"
              label="Company"
            />
          </Box>
          <TextField
            value={yearsOnCompany}
            fullWidth
            label={<Typography>Years on Company</Typography>}
            style={{
              margin: '8px 8px 8px 0px',
            }}
            type="number"
            margin="normal"
            variant="outlined"
            onChange={(e) => setYearsOnCompany(+e.target.value)}
          />
          <TextField
            value={salary}
            fullWidth
            label={<Typography>Salary</Typography>}
            style={{
              margin: '8px 8px 8px 0px',
            }}
            type="number"
            margin="normal"
            variant="outlined"
            onChange={(e) => setSalary(+e.target.value)}
          />
        </Box>
        <DialogActions style={{ justifyContent: 'flex-start' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddCompany}
          >
            <Box gridGap={5} display="flex" alignContent="center">
              {isLoading && <ClipLoader color="#FFFFFF" size={30} />}
              <Typography>Add</Typography>
            </Box>
          </Button>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default AddCompanyDialog;
