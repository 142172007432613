import { Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import Auth from "../../auth";
import { ErrorDialog } from "../ErrorDialog";
import { SuccessDialog } from "../SuccessDialog";
import close from '../../assets/images/close.png';

export const RejectApplicationDialog: React.FC<{
    open: boolean;
    setOpen: () => void;
    loadUser?: () => void;
    rejectType?: any | null;
    sourceIDReject?: any | null;
    source?: any | null;
    user: any | null;
}> = ({source, user, open, setOpen, sourceIDReject, rejectType, loadUser}) => {

    const [note, setNote] = useState('')
    const [type, setType] = useState(rejectType !== undefined ? rejectType : '');
    const [sourceID, setSourceID] = useState(sourceIDReject !== undefined ? sourceIDReject :'');
    const [openSuccess, setOpenSuccess] = useState(false);
    const [dialogMsg, setDialogMsg] = useState('');
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [error, setError] = useState<string | null>(null)
    
    
    const rejectApplication = () => {
        Auth.dashboard.rejectApplication(
            note,
            sourceID,
            user,
            type,
            (resp) => {
                console.log(resp)
                setOpenSuccess(true)
                setDialogMsg("A user's application has been rejected.")
                if(loadUser !== undefined){
                    loadUser();
                }
                
                setOpen();
            },
            (e) => {
                console.log(e)
                setError(e.error)
                setOpenErrorDialog(true)
            }
        )
    }

    return(
        <>
        <Dialog open={open} fullWidth onClose={setOpen}>
            <DialogTitle>
                <img
                    src={close}
                    style={{cursor:'pointer', position: 'absolute', right: '24px', marginTop: '5px', width: '20px'}}
                    onClick={() =>setOpen()} 
                />
                <Typography style={{fontSize: '20px', fontWeight: 'bold'}} color="primary">
                    {type === 'sell_authorize_rejected' ? 'Reject Application' : type === 'installment_authorize_rejected' ? 'Reject Installment' : '' }
                </Typography>
                
            </DialogTitle>
            <DialogContent>
                <Typography style={{fontSize: '16px', textAlign: 'center'}}>
                    This will reject this user's <span style={{fontSize: '16px', fontWeight: 'bold', color: '#f47723', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'}}>{type === 'sell_authorize_rejected' ? 'merchant application' : 'installment application'}</span>.
                </Typography>
                <Typography style={{fontSize: '16px', textAlign: 'center'}}>
                    Please write a reason for the rejection of the application.
                </Typography>
                {type === '' && (
                    <>
                <Typography style={{fontSize: '16px'}} color="primary">
                    Choose type
                </Typography>
                <Select
                    id="demo-simple-select-autowidth"
                    name="type"
                    value={type}
                    fullWidth
                    variant="outlined"
                    
                    onChange={(e: any)=>{
                        setType(e.target.value); 
                        if(e.target.value === 'sell_authorize_rejected') {
                            setSourceID('')
                        }
                    }}
                >
                    <MenuItem value="" disabled>
                        <em>Choose Rejection type...</em>
                    </MenuItem>
                    <MenuItem value={"sell_authorize_rejected"}>Sell Authorized Rejected</MenuItem>
                    <MenuItem value={"installment_authorize_rejected"}>Installment Authorized Rejected</MenuItem>
                    
            
                </Select>
                    </>
                )}
                {type !== '' && (
                    <>
                    <TextField 
                        type="text"
                        name="note"
                        fullWidth
                        style={{paddingTop: '20px'}}
                        variant="outlined"
                        placeholder="Enter note.."
                        onChange={(e) => setNote(e.target.value)}
                        value={note}
                    />
                    
                    {(type === "installment_authorize_rejected" && (sourceIDReject === undefined && sourceIDReject === '')) && (
                        <>
                        <Typography style={{fontSize: '16px', paddingTop: '20px'}} color="primary">
                            Choose source
                        </Typography>
                        <Select
                            id="demo-simple-select-autowidth"
                            name="type"
                            value={sourceID}
                            fullWidth
                            variant="outlined"
                            
                            onChange={(e: any)=>{setSourceID(e.target.value);}}
                        >
                            <MenuItem value="" disabled>
                                <em>Choose source...</em>
                            </MenuItem>
                            {source && source.map((card: any) => (
                                <MenuItem key={card.id} value={card.id}>{card.type + ' - '+card.name}</MenuItem>
                            ))}
                            
                    
                        </Select>
                        </>
                    )}
                    </>
                )}
                
                
                
            </DialogContent>
            <DialogActions>
                <div className="button-tool">
                    <Typography style={{fontSize: 16, color: 'white'}} onClick={rejectApplication}>Submit</Typography>
                </div>
                <div className="button-delete">
                    <Typography style={{fontSize: 16, color: 'white'}} onClick={setOpen}>Close</Typography>
                </div>
            </DialogActions>
        </Dialog>
        <SuccessDialog openDialog={openSuccess} setOpenDialog={setOpenSuccess} dialogMessage={dialogMsg}/>
        <ErrorDialog openDialog={openErrorDialog} setOpenDialog={setOpenErrorDialog} dialogMessage={error} />
        </>
    )
}