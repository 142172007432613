import React, { useState, useEffect } from 'react';
import {
  Container,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Card,
  CardContent,
  Divider,
  Typography,
} from '@material-ui/core';
import './style.scss';
import { BottomToolbar, PasswordConfirmationDialog } from '../../components';
import { StaffParamTypes } from '../../interface';
import { useHistory, useParams } from 'react-router';
import Auth from '../../auth';
import { ErrorDialog } from '../../components/ErrorDialog';
import { SuccessDialog } from '../../components/SuccessDialog';
import CompaniesList from '../../components/CompaniesList';

let test = {
  id: 3,
  first_name: 'Testingg',
  last_name: 'Staff',
  email: 'testing@test.com',
  has_full_access: true,
  manage_users: false,
  manage_transactions: false,
  manage_proposal: false,
  manage_staffs: false,
};

const StaffDetails: React.FC<{
  setLoadingOverlay: (e: any) => void;
}> = ({ setLoadingOverlay }) => {
  const { id, type } = useParams<StaffParamTypes>();
  const [first_name, setFirstName] = useState<any>('');
  const [last_name, setLastName] = useState<any>('');
  const [email, setEmail] = useState<any>('');
  const [password, setPassword] = useState<any>('');
  const [has_full_access, setFullAccess] = useState<any>(false);
  const [manageUsers, setManageUsers] = useState<any>(false);
  const [manageTransactions, setManageTransactions] = useState<any>(false);
  const [manageProposals, setManageProposals] = useState<any>(false);
  const [manageStaffs, setManageStaffs] = useState<any>(false);
  const [managePayouts, setManagePayouts] = useState<any>(false);
  const [manageServiceTypes, setManageServiceTypes] = useState<any>(false);
  const [manageSourceTypes, setManageSourceTypes] = useState<any>(false);
  const [manageCompanies, setManageCompanies] = useState<any>(false);
  const [openPassword, setOpenPassword] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [dialogMsg, setDialogMsg] = useState('');
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedCompanyID, setSelectedCompanyID] = useState(0);
  const [companyName, setCompanyName] = useState('');
  const history = useHistory();

  useEffect(() => {
    if (type === 'view' && email === '') {
      setLoadingOverlay(true);
      Auth.dashboard.getStaffDetails(
        id,
        (resp) => {
          console.log(resp);
          setFirstName(resp.first_name);
          setLastName(resp.last_name);
          setEmail(resp.email);
          setLoadingOverlay(false);
          setFullAccess(resp.has_full_access);
          setManageUsers(resp.manager_users);
          setManageTransactions(resp.manage_transactions);
          setManageProposals(resp.manage_proposal);
          setManageStaffs(resp.manage_staffs);
          setManagePayouts(resp.manage_payouts);
          setManageServiceTypes(resp.manage_service_types);
          setManageCompanies(resp.manage_companies);
          if (resp.companies.length > 0) {
            setCompanyName(resp.companies[0].name);
            setSelectedCompanyID(resp.companies[0].id);
          }
        },
        (e) => {
          console.log(e);
          setLoadingOverlay(false);
          setError(e.error ? e.error : e.detail + ' Redirecting you...');
          setTimeout(function () {
            history.push('/admin/staffs');
          }, 2000);
          setOpenErrorDialog(true);
        }
      );
    }
  }, []);

  const updateStaff = () => {
    setLoadingOverlay(true);
    Auth.dashboard.editStaff(
      id,
      email,
      first_name,
      last_name,
      has_full_access,
      manageUsers,
      manageTransactions,
      manageStaffs,
      managePayouts,
      manageServiceTypes,
      manageProposals,
      manageCompanies,
      [selectedCompanyID],
      (resp) => {
        console.log(resp);
        setOpenSuccess(true);
        setLoadingOverlay(false);
        setDialogMsg('Staff has been successfully updated!');
      },
      (e) => {
        console.log(e);
        setError(e.error);
        setLoadingOverlay(false);
        setOpenErrorDialog(true);
      }
    );
  };

  const createStaff = () => {
    setLoadingOverlay(true);
    Auth.dashboard.addStaff(
      email,
      password,
      first_name,
      last_name,
      has_full_access,
      manageUsers,
      manageTransactions,
      manageStaffs,
      manageProposals,
      [selectedCompanyID],
      (resp) => {
        console.log(resp);
        setLoadingOverlay(false);
        history.push(`/admin/staff/view/${resp.id}`);
      },
      (e) => {
        console.log(e);
        setError(e.error);
        setLoadingOverlay(false);
        setOpenErrorDialog(true);
      }
    );
  };

  const deleteStaff = () => {
    Auth.dashboard.deleteStaff(
      id,
      (resp) => {
        history.goBack();
      },
      (e) => {
        console.log(e);
        setError(e.error);
        setOpenErrorDialog(true);
      }
    );
  };

  console.log('company Name', selectedCompanyID);

  return (
    <div className="page-content staff-details">
      <div className="staff-details-container">
        <Container fixed>
          <Grid
            container
            direction="row"
            justify="center"
            xs={12}
            spacing={5}
            sm={12}
          >
            <Grid container direction="row" spacing={2} xs={8} item>
              <Grid item>
                <Card>
                  <CardContent>
                    <Grid container spacing={2} xs={12}>
                      <Grid item xs={12}>
                        <div className="staff-title">
                          <Typography
                            style={{
                              fontSize: '20px',
                            }}
                            color="primary"
                          >
                            Staff Information
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          value={first_name}
                          name="first_name"
                          fullWidth
                          label={<Typography>First Name</Typography>}
                          style={{
                            margin: '8px 8px 8px 0px',
                          }}
                          margin="normal"
                          variant="outlined"
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          value={last_name}
                          name="last_name"
                          fullWidth
                          label={<Typography>Last Name</Typography>}
                          style={{
                            margin: '8px 8px 8px 0px',
                          }}
                          margin="normal"
                          variant="outlined"
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={type === 'view' ? 12 : 6}>
                        <TextField
                          value={email}
                          name="email"
                          fullWidth
                          label={<Typography>Email</Typography>}
                          style={{
                            margin: '8px 8px 8px 0px',
                          }}
                          margin="normal"
                          variant="outlined"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Grid>
                      {type === 'create' && (
                        <Grid item xs={6}>
                          <TextField
                            value={password}
                            name="password"
                            fullWidth
                            label={<Typography>Password</Typography>}
                            style={{
                              margin: '8px 8px 8px 0px',
                            }}
                            margin="normal"
                            variant="outlined"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </Grid>
                      )}
                      <Grid item xs={type === 'view' ? 12 : 6}>
                        <CompaniesList
                          setSelectedCompanyID={setSelectedCompanyID}
                          initialCompanyID={selectedCompanyID}
                          variant="outlined"
                          label={companyName}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container item direction="row" spacing={2} xs={12} sm={4}>
              <Grid item>
                <Card>
                  <CardContent>
                    <Grid container spacing={2} xs={12}>
                      <Grid item xs={12}>
                        <Typography
                          style={{ fontSize: '20px' }}
                          color="primary"
                        >
                          Permissions
                        </Typography>

                        <Divider />
                      </Grid>
                      <Grid item xs={12}>
                        Expand or restrict user's permissions to access certain
                        part of the dashboard.
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={has_full_access}
                              onChange={() => setFullAccess(!has_full_access)}
                              name="active"
                            />
                          }
                          label={
                            <Typography
                              style={{
                                fontSize: '16px',
                              }}
                            >
                              User has full access
                            </Typography>
                          }
                        />
                        {!has_full_access && (
                          <>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={manageUsers}
                                  onChange={() => setManageUsers(!manageUsers)}
                                  name="active"
                                />
                              }
                              label={
                                <Typography
                                  style={{
                                    fontSize: '16px',
                                  }}
                                >
                                  Manage Users
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={manageTransactions}
                                  onChange={() =>
                                    setManageTransactions(!manageTransactions)
                                  }
                                  name="active"
                                />
                              }
                              label={
                                <Typography
                                  style={{
                                    fontSize: '16px',
                                  }}
                                >
                                  Manage Transactions
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={manageProposals}
                                  onChange={() =>
                                    setManageProposals(!manageProposals)
                                  }
                                  name="active"
                                />
                              }
                              label={
                                <Typography
                                  style={{
                                    fontSize: '16px',
                                  }}
                                >
                                  Manage Proposals
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={manageStaffs}
                                  onChange={() =>
                                    setManageStaffs(!manageStaffs)
                                  }
                                  name="active"
                                />
                              }
                              label={
                                <Typography
                                  style={{
                                    fontSize: '16px',
                                  }}
                                >
                                  Manage Staffs
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={managePayouts}
                                  onChange={() =>
                                    setManagePayouts(!managePayouts)
                                  }
                                  name="active"
                                />
                              }
                              label={
                                <Typography
                                  style={{
                                    fontSize: '16px',
                                  }}
                                >
                                  Manage Payouts
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={manageServiceTypes}
                                  onChange={() =>
                                    setManageServiceTypes(!manageServiceTypes)
                                  }
                                  name="active"
                                />
                              }
                              label={
                                <Typography
                                  style={{
                                    fontSize: '16px',
                                  }}
                                >
                                  Manage Service Types
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={manageCompanies}
                                  onChange={() =>
                                    setManageCompanies(!manageCompanies)
                                  }
                                  name="active"
                                />
                              }
                              label={
                                <Typography
                                  style={{
                                    fontSize: '16px',
                                  }}
                                >
                                  Manage Companies
                                </Typography>
                              }
                            />
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
      <PasswordConfirmationDialog
        open={openPassword}
        setOpen={setOpenPassword}
        onFinish={deleteStaff}
      />
      <BottomToolbar
        btnDel={() => setOpenPassword(true)}
        btnSave={type === 'view' ? updateStaff : createStaff}
      />
      <SuccessDialog
        openDialog={openSuccess}
        setOpenDialog={setOpenSuccess}
        dialogMessage={dialogMsg}
      />
      <ErrorDialog
        openDialog={openErrorDialog}
        setOpenDialog={setOpenErrorDialog}
        dialogMessage={error}
      />
    </div>
  );
};

export default StaffDetails;
