import React, { useState, useCallback, useContext, useEffect } from 'react';
import { Link, useHistory, withRouter, useLocation } from 'react-router-dom';
import { Form, Container } from 'react-bootstrap';
import { TextLoginInput, Button } from '../../components';
import './style.scss';
import Auth from '../../auth';
import { ErrorDialog } from '../../components/ErrorDialog';
import { SuccessDialog } from '../../components/SuccessDialog';

const Login: React.FC<{
  setAdmin: (val: any) => void;
  setLoadingOverlay: (val: boolean) => void;
}> = ({ setLoadingOverlay }) => {
  const history = useHistory();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [openSuccess, setOpenSuccess] = useState(false);
  const [dialogMsg, setDialogMsg] = useState('');
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const submitLogin = () => {
    setLoadingOverlay(true);
    Auth.login(
      email,
      password,
      (response) => {
        setLoadingOverlay(false);
        if (response.data.has_full_access) {
          history.push('/admin/home');
        } else {
          history.push('/admin/users');
        }
      },
      (e) => {
        setLoadingOverlay(false);
        setError(e.error);
        setOpenErrorDialog(true);
        console.log(e);
      }
    );
  };

  return (
    <div className="login">
      <Container>
        <div className="login-container">
          <Form>
            <div className="form-container">
              <TextLoginInput
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="E-mail"
                icon={{ prefix: 'far', name: 'envelope' }}
              />
            </div>

            <div className="form-container">
              <TextLoginInput
                name="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                icon={{ prefix: 'far', name: 'envelope' }}
              />
            </div>

            <Button
              onClick={() => {
                console.log('Submiot');
                submitLogin();
              }}
              color={'login-primary'}
              className="login-button fg-button-block form-button"
            >
              Submit
            </Button>
          </Form>
        </div>
      </Container>
      <SuccessDialog
        openDialog={openSuccess}
        setOpenDialog={setOpenSuccess}
        dialogMessage={dialogMsg}
      />
      <ErrorDialog
        openDialog={openErrorDialog}
        setOpenDialog={setOpenErrorDialog}
        dialogMessage={error}
      />
    </div>
  );
};

export default Login;
