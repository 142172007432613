import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  TextField,
  Card,
  CardContent,
  Divider,
  Typography,
} from '@material-ui/core';
import './style.scss';
import { BottomToolbar, PasswordConfirmationDialog } from '../../components';
import { StaffParamTypes } from '../../interface';
import { useHistory, useParams } from 'react-router';
import Auth from '../../auth';
import { ErrorDialog } from '../../components/ErrorDialog';
import { SuccessDialog } from '../../components/SuccessDialog';
import CompaniesList from '../../components/CompaniesList';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ApiRequest } from '../../services/http';
import URL from '../../services/url';

interface Company {
  name: string;
  contact_person: string;
  contact_number: string;
  email: string;
}

interface Address {
  company_id: number;
  street: string;
  barangay: string;
  city: string;
  region: string;
  zip_code: string;
}

const CompanyDetails: React.FC<{
  setLoadingOverlay: (e: any) => void;
}> = ({ setLoadingOverlay }) => {
  const { id, type } = useParams<StaffParamTypes>();
  const [openPassword, setOpenPassword] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [dialogMsg, setDialogMsg] = useState('');
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [companyID, setCompanyID] = useState(0);
  const [name, setName] = useState('');
  const [addressID, setAddressID] = useState(0);
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [contact_person, setContactPerson] = useState('');
  const [contact_number, setContactNumber] = useState('');
  const [street, setStreet] = useState('');
  const [barangay, setBarangay] = useState('');
  const [city, setCity] = useState('');
  const [region, setRegion] = useState('');
  const [zip_code, setZipCode] = useState('');
  const history = useHistory();

  const { mutate: mutateCreateCompanyAddress, isSuccess } = useMutation(
    (data: Address) => {
      return ApiRequest.post(URL.DASHBOARD.COMPANY_ADDRESS_CREATE, data);
    }
  );

  const { mutate: mutateCreateCompany } = useMutation(
    (data: Company) => {
      return ApiRequest.post(`${URL.DASHBOARD.COMPANIES}/`, data);
    },
    {
      onSuccess: (response) => {
        mutateCreateCompanyAddress({
          company_id: response.data.id,
          street,
          barangay,
          city,
          region,
          zip_code,
        });
      },
    }
  );

  const { mutate: mutateEditCompany } = useMutation(
    (data: Company) => {
      return ApiRequest.put(`${URL.DASHBOARD.COMPANIES}/${id}/`, data);
    },
    {
      onSuccess: (response) => {
        mutateCreateCompanyAddress({
          company_id: response.data.id,
          street,
          barangay,
          city,
          region,
          zip_code,
        });
      },
    }
  );

  const { data, isLoading } = useQuery(
    ['company'],
    async () => {
      const response = await fetch(`${URL.DASHBOARD.COMPANIES}/${id}`, {
        headers: {
          Authorization: `Token ${Auth.key()}`,
        },
      });
      return response.json();
    },
    {
      enabled: id !== undefined,
      onSuccess: (data) => {
        setEmail(data.email);
        setName(data.name);
        setAddress(data.address);
        setContactNumber(data.contact_number);
        setContactPerson(data.contact_person);
        setAddressID(data.addresses.length > 0 ? data.addresses[0] : 0);
      },
    }
  );

  const { data: companyAddress } = useQuery(
    ['companyAddress'],
    async () => {
      const response = await fetch(
        `${URL.DASHBOARD.COMPANY_ADDRESS}/${addressID}`,
        {
          headers: {
            Authorization: `Token ${Auth.key()}`,
          },
        }
      );
      return response.json();
    },
    {
      enabled: addressID !== 0,
      onSuccess: (data) => {
        setStreet(data.street);
        setBarangay(data.barangay);
        setCity(data.city);
        setRegion(data.region);
        setZipCode(data.zip_code);
      },
    }
  );

  const handleOnSubmit = () => {
    if (type === 'create') {
      mutateCreateCompany({
        name,
        contact_number,
        contact_person,
        email,
      });
    } else if (type === 'edit') {
      mutateEditCompany({
        name,
        contact_number,
        contact_person,
        email,
      });
    }
  };

  const deleteStaff = () => {
    Auth.dashboard.deleteStaff(
      id,
      (resp) => {
        history.goBack();
      },
      (e) => {
        console.log(e);
        setError(e.error);
        setOpenErrorDialog(true);
      }
    );
  };

  useEffect(() => {
    if (isSuccess) {
      if (type === 'create') {
        setOpenSuccess(true);
        setDialogMsg('Request success');
        history.push('/admin/companies');
      } else if (type === 'edit') {
        setOpenSuccess(true);
        setDialogMsg('Company Edited');
      }
    }
  }, [isSuccess]);

  return (
    <div className="page-content staff-details">
      <div className="staff-details-container">
        <Container fixed>
          <Grid
            container
            direction="row"
            justify="center"
            xs={12}
            spacing={5}
            sm={12}
          >
            <Grid container direction="row" spacing={2} xs={8} item>
              <Grid item>
                <Card>
                  <CardContent>
                    <Grid container spacing={2} xs={12}>
                      <Grid item xs={12}>
                        <div className="staff-title">
                          <Typography
                            style={{
                              fontSize: '20px',
                            }}
                            color="primary"
                          >
                            Company Details
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          value={name}
                          name="name"
                          fullWidth
                          label={<Typography>Company Name</Typography>}
                          style={{
                            margin: '8px 8px 8px 0px',
                          }}
                          margin="normal"
                          variant="outlined"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Grid>

                      <Grid item xs={type === 'view' ? 12 : 6}>
                        <TextField
                          value={email}
                          name="email"
                          fullWidth
                          label={<Typography>Email</Typography>}
                          style={{
                            margin: '8px 8px 8px 0px',
                          }}
                          margin="normal"
                          variant="outlined"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={type === 'view' ? 12 : 6}>
                        <TextField
                          value={contact_person}
                          name="contact_person"
                          fullWidth
                          label={<Typography>Contact Person</Typography>}
                          style={{
                            margin: '8px 8px 8px 0px',
                          }}
                          margin="normal"
                          variant="outlined"
                          onChange={(e) => setContactPerson(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={type === 'view' ? 12 : 6}>
                        <TextField
                          value={contact_number}
                          name="contact_number"
                          fullWidth
                          label={<Typography>Contact Number</Typography>}
                          style={{
                            margin: '8px 8px 8px 0px',
                          }}
                          margin="normal"
                          variant="outlined"
                          type="number"
                          onChange={(e) => setContactNumber(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item>
                <Card>
                  <CardContent>
                    <Grid container spacing={2} xs={12}>
                      <Grid item xs={6}>
                        <TextField
                          value={street}
                          name="street"
                          fullWidth
                          label={<Typography>Street</Typography>}
                          style={{
                            margin: '8px 8px 8px 0px',
                          }}
                          margin="normal"
                          variant="outlined"
                          onChange={(e) => setStreet(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          value={barangay}
                          name="barangay"
                          fullWidth
                          label={<Typography>Barangay</Typography>}
                          style={{
                            margin: '8px 8px 8px 0px',
                          }}
                          margin="normal"
                          variant="outlined"
                          onChange={(e) => setBarangay(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={type === 'view' ? 12 : 6}>
                        <TextField
                          value={city}
                          name="city"
                          fullWidth
                          label={<Typography>City</Typography>}
                          style={{
                            margin: '8px 8px 8px 0px',
                          }}
                          margin="normal"
                          variant="outlined"
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={type === 'view' ? 12 : 6}>
                        <TextField
                          value={region}
                          name="email"
                          fullWidth
                          label={<Typography>Province</Typography>}
                          style={{
                            margin: '8px 8px 8px 0px',
                          }}
                          margin="normal"
                          variant="outlined"
                          onChange={(e) => setRegion(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={type === 'view' ? 12 : 6}>
                        <TextField
                          value={zip_code}
                          name="email"
                          fullWidth
                          label={<Typography>Zip Code</Typography>}
                          style={{
                            margin: '8px 8px 8px 0px',
                          }}
                          margin="normal"
                          variant="outlined"
                          onChange={(e) => setZipCode(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Grid
              container
              item
              direction="row"
              spacing={2}
              xs={12}
              sm={4}
            ></Grid>
          </Grid>
        </Container>
      </div>
      <PasswordConfirmationDialog
        open={openPassword}
        setOpen={setOpenPassword}
        onFinish={deleteStaff}
      />
      <BottomToolbar
        btnDel={() => setOpenPassword(true)}
        btnSave={handleOnSubmit}
      />
      <SuccessDialog
        openDialog={openSuccess}
        setOpenDialog={setOpenSuccess}
        dialogMessage={dialogMsg}
      />
      <ErrorDialog
        openDialog={openErrorDialog}
        setOpenDialog={setOpenErrorDialog}
        dialogMessage={error}
      />
    </div>
  );
};

export default CompanyDetails;
