import { ApiRequest, HttpRequest } from '../services/http';
import URL from '../services/url';
import { AdminUser } from '../models';
import Cookies from 'js-cookie';
import moment from 'moment';
// AUTH localstorage
// isAuthenticated: boolean
//localStorage.getItem('key')

const Auth = {
  key: () => {
    var newKey;
    if (Cookies.get('admin-key')) {
      newKey = Cookies.get('admin-key');
      console.log('Key Received');
      return newKey;
    } else {
      console.log('Did not receive key');
      return null;
    }
  },
  isAuthenticated: () => {
    const isAuthenticated = Cookies.get('admin-key');
    console.log('Key: ' + isAuthenticated);
    if (isAuthenticated) {
      return isAuthenticated !== null;
    } else {
      console.log(isAuthenticated);
    }
  },

  login: (
    email: string,
    password: string,
    success: (e?: any) => void,
    failed: (e?: any) => void
  ) => {
    HttpRequest.post(URL.LOGIN, {
      email,
      password,
    })
      .then((response) => {
        localStorage.setItem('admin-key', response.data.key);
        Cookies.set('admin-key', response.data.key);
        console.log(response.data.key);
        success(response);
      })
      .catch((e) => {
        console.log(e);
        console.log(e.response);
        failed(e.response.data);
      });
  },

  register: (
    username: string,
    email: string,
    password1: string,
    password2: string,
    success: (e?: any) => void,
    failed: (e?: any) => void
  ) => {
    HttpRequest.post(URL.REGISTRATION, {
      username,
      email,
      password: password1,
      password2,
    })
      .then((response) => {
        localStorage.setItem('admin-key', response.data.key);
        Cookies.set('admin-key', response.data.key);
        success(response.data);
      })
      .catch((e) => {
        failed(e.response.data);
      });
  },

  dashboard: {
    getUsers: (
      args: string | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.get(URL.DASHBOARD.USERS + args)
        .then((response) => {
          success(response.data);
        })
        .catch((e) => {
          failed(e.data);
        });
    },

    getTransactions: (
      args: string | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.get(URL.DASHBOARD.TRANSACTIONS + args)
        .then((response) => {
          success(response.data);
        })
        .catch((e) => {
          failed(e.data);
        });
    },

    getProposals: (
      args: string | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.get(URL.DASHBOARD.PROPOSALS + args)
        .then((response) => {
          success(response.data);
        })
        .catch((e) => {
          failed(e.data);
        });
    },

    getStaffs: (
      args: string | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.get(URL.DASHBOARD.STAFFS + args)
        .then((response) => {
          success(response.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    getAds: (
      args: string | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.get(URL.DASHBOARD.NOTIFICATIONS.ADS + args)
        .then((response) => {
          success(response.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    createAd: (
      image: any,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.post(URL.DASHBOARD.NOTIFICATIONS.ADS + '/', {
        image,
      })
        .then((response) => {
          success(response.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    updateAd: (
      id: any,
      image: any,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.patch(URL.DASHBOARD.NOTIFICATIONS.ADS + '/' + id + '/', {
        image,
      })
        .then((response) => {
          success(response.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    deleteAd: (
      id: any,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.delete(URL.DASHBOARD.NOTIFICATIONS.ADS + '/' + id + '/')
        .then((response) => {
          success(response.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    deleteRejectionNote: (
      id: any,
      sid: any,
      value: any,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      console.log(value);
      let val =
        value === 'installment_authorize_rejected'
          ? 'installmentAuthorized'
          : value === 'sell_authorize_rejected'
          ? 'sellAuthorized'
          : 'buyAuthorized';
      if (val === 'installmentAuthorized') {
        ApiRequest.patch(URL.DASHBOARD.PAYMENT_SOURCE + `/${sid}`, {
          [val]: true,
        })
          .then((resp) => {
            success(resp.data);
          })
          .catch((e) => {
            failed(e.response.data);
          });
      } else {
        ApiRequest.patch(URL.DASHBOARD.USERS + id + `/`, {
          [val]: true,
        })
          .then((resp) => {
            success(resp.data);
          })
          .catch((e) => {
            failed(e.response.data);
          });
      }
    },

    getActivities: (
      args: string | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.get(URL.DASHBOARD.ACTIVITIES + args)
        .then((response) => {
          success(response.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    getPaymentTypes: (
      args: string | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.get(URL.DASHBOARD.SERVICE_TYPES + args)
        .then((response) => {
          success(response.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    rejectApplication: (
      note: string | null,
      payment_source: any | null,
      user: any | null,
      type: any | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.post(URL.DASHBOARD.REJECT_APPLICATION, {
        note,
        payment_source,
        user,
        type,
      })
        .then((resp) => {
          success(resp.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    getCategory: (
      args: string | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.get(URL.DASHBOARD.CATEGORY + args)
        .then((response) => {
          success(response.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    addPaymentType: (
      name: string | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.post(URL.DASHBOARD.SERVICE_TYPES + '/', {
        name,
      })
        .then((response) => {
          success(response.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    addCategory: (
      name: string | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.post(URL.DASHBOARD.CATEGORY, {
        name,
      })
        .then((response) => {
          success(response.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    delPaymentType: (
      id: any | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.delete(URL.DASHBOARD.SERVICE_TYPES + `/${id}`)
        .then((response) => {
          success(response.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    delCategory: (
      id: any | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.delete(URL.DASHBOARD.CATEGORY + id)
        .then((response) => {
          success(response.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    getSummary: (
      args: string | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.get(URL.DASHBOARD.SUMMARY + args)
        .then((response) => {
          success(response.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    getPayouts: (
      args: string | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.get(URL.DASHBOARD.PAYOUTS + args)
        .then((resp) => {
          success(resp.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    getPayoutDetails: (
      id: string | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.get(URL.DASHBOARD.PAYOUTS + `/${id}`)
        .then((resp) => {
          success(resp.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    getSources: (
      args: string | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.get(URL.DASHBOARD.PAYMENT_SOURCES + args)
        .then((response) => {
          success(response.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    getSourceDetails: (
      id: string | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.get(URL.DASHBOARD.PAYMENT_SOURCE + '/' + id)
        .then((response) => {
          success(response.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    editSourceDetails: (
      id: any | null,
      name: any | null,
      reference_id: any | null,
      source_type: any | null,
      installmentAuthorized: any | null,
      spendLimit: any | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.patch(URL.DASHBOARD.PAYMENT_SOURCE + '/' + id, {
        name,
        reference_id,
        source_type,
        installmentAuthorized,
        spendLimit,
      })
        .then((response) => {
          success(response.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    editSourceCardStatement: (
      id: any | null,
      Cardstatement: any | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.patch(URL.DASHBOARD.PAYMENT_SOURCE + '/' + id, {
        Cardstatement,
      })
        .then((response) => {
          success(response.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    getUserDetails: (
      id: any | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.get(URL.DASHBOARD.USERS + id)
        .then((resp) => {
          success(resp.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    getTransactionsDetails: (
      id: any | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.get(URL.DASHBOARD.TRANSACTIONS + `/${id}`)
        .then((resp) => {
          success(resp.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    editTransactionDetails: (
      id: any | null,
      name: string | null,
      description: string | null,
      merchant: any | null,
      buyer: any | null,
      amount: any | null,
      monthly: any | null,
      transaction_fee: any | null,
      interest: any | null,
      interest_charge: any | null,
      terms: any | null,
      status: any | null,
      category: any | null,
      transaction_type: any | null,
      completed_payments: any | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.patch(URL.DASHBOARD.TRANSACTIONS + `/${id}/`, {
        name,
        description,
        merchant,
        buyer,
        amount,
        monthly,
        transaction_fee,
        interest,
        interest_charge,
        terms,
        status,
        category,
        transaction_type,
        completed_payments,
      })
        .then((resp) => {
          success(resp);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    getProposalsDetails: (
      id: any | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.get(URL.DASHBOARD.PROPOSALS + id)
        .then((resp) => {
          success(resp.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    editProposalDetails: (
      id: any | null,
      name: string | null,
      description: string | null,
      merchant: any | null,
      buyer: any | null,
      amount: any | null,
      monthly: any | null,
      transaction_fee: any | null,
      interest: any | null,
      interest_charge: any | null,
      terms: any | null,
      status: any | null,
      category: any | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.patch(URL.DASHBOARD.PROPOSALS + id + '/', {
        name,
        description,
        merchant,
        buyer,
        amount,
        monthly,
        transaction_fee,
        interest,
        interest_charge,
        terms,
        status,
        category,
      })
        .then((resp) => {
          success(resp);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    getStaffDetails: (
      id: any | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.get(URL.DASHBOARD.STAFFS + `${id}`)
        .then((resp) => {
          success(resp.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    addStaff: (
      email: string | null,
      password: string | null,
      first_name: string | null,
      last_name: string | null,
      has_full_access: boolean | null,
      manager_users: boolean | null,
      manage_transactions: boolean | null,
      manage_staffs: boolean | null,
      manage_proposal: boolean | null,
      companies: number[] | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.post(URL.DASHBOARD.STAFF_CREATE, {
        email,
        password,
        first_name,
        last_name,
        has_full_access,
        manager_users,
        manage_transactions,
        manage_staffs,
        manage_proposal,
        companies,
      })
        .then((resp) => {
          success(resp.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    editStaff: (
      id: string | null,
      email: string | null,
      first_name: string | null,
      last_name: string | null,
      has_full_access: boolean | null,
      manager_users: boolean | null,
      manage_transactions: boolean | null,
      manage_staffs: boolean | null,
      manage_payouts: boolean | null,
      manage_service_types: boolean | null,
      manage_proposal: boolean | null,
      manage_companies: boolean | null,
      company_ids: number[] | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.patch(URL.DASHBOARD.STAFFS + `${id}`, {
        email,
        first_name,
        last_name,
        has_full_access,
        manager_users,
        manage_transactions,
        manage_staffs,
        manage_payouts,
        manage_service_types,
        manage_proposal,
        manage_companies,
        company_ids,
      })
        .then((resp) => {
          success(resp.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    editUser: (
      id: string | null,
      username: string | null,
      email: string | null,
      first_name: string | null,
      last_name: string | null,
      contact_number: string | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.patch(URL.DASHBOARD.USERS + `${id}/`, {
        username,
        email,
        first_name,
        last_name,

        contact_number,
      })
        .then((resp) => {
          success(resp.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    editUserDetails: (
      id: any,
      objVal: any,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      if (objVal !== undefined) {
        let obj = objVal.newUserObj;
        console.log(obj);
        console.log(objVal);

        ApiRequest.patch(URL.DASHBOARD.USERS + `${id}/`, objVal)
          .then((resp) => {
            success(resp.data);
          })
          .catch((e) => {
            failed(e.response.data);
          });
      }
    },

    editAuth: (
      id: string | null,
      valObj: any,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.patch(URL.DASHBOARD.USERS + `${id}/`, {
        [valObj.name]: valObj.checked,
      })
        .then((resp) => {
          success(resp.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    getUserPaymentSources: (
      id: string | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.get(URL.DASHBOARD.USERS + `${id}/payment_sources?page_size=5`)
        .then((resp) => {
          success(resp.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    getUserActivities: (
      id: string | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.get(URL.DASHBOARD.USERS + `${id}/activities?page_size=5`)
        .then((resp) => {
          success(resp.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    getPaymentSchedules: (
      args: string | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.get(URL.DASHBOARD.PAYMENT_SCHEDULES + `${args}`)
        .then((resp) => {
          success(resp.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    deleteSource: (
      id: string | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.delete(URL.DASHBOARD.PAYMENT_SOURCE + `/${id}`)
        .then((resp) => {
          success(resp.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    deleteUser: (
      id: string | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.delete(URL.DASHBOARD.USERS + `${id}`)
        .then((resp) => {
          success(resp.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    deleteStaff: (
      id: string | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.delete(URL.DASHBOARD.STAFFS + `${id}`)
        .then((resp) => {
          success(resp.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    passwordConfirm: (
      email: string | null,
      password: string | null,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      HttpRequest.post(URL.LOGIN, {
        email,
        password,
      })
        .then((resp) => {
          success(resp.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    sendPayouts: (
      payout_ids: any,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.post(URL.DASHBOARD.SEND_PAYOUTS, {
        payout_ids,
      })
        .then((resp) => {
          success(resp.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },

    patchPaymentStatus: (
      id: any,
      status: string,
      success: (e?: any) => void,
      failed: (e?: any) => void
    ) => {
      ApiRequest.patch(URL.DASHBOARD.PAYMENT_OBJECT + id, {
        status,
      })
        .then((resp) => {
          success(resp.data);
        })
        .catch((e) => {
          failed(e.response.data);
        });
    },
  },

  getTransaction: (
    id: any | undefined,
    success: (e?: any) => void,
    failed: (e?: any) => void
  ) => {
    ApiRequest.get(URL.TRANSACTIONS.TRANSACTIONS_BASE + '/' + id + '/')
      .then((response) => {
        console.log(response);
        success(response.data);
      })
      .catch((e) => {
        console.log(e.response.data);
        failed(e.response.data);
      });
  },

  updateProposal: (
    id: any,
    terms: any,
    category: any,
    success: (e?: any) => void,
    failed: (e?: any) => void
  ) => {
    ApiRequest.patch(URL.PROPOSALS.PROPOSAL + id + '/', {
      terms,
      category,
    })
      .then((response) => {
        console.log(response.data);
        success(response.data);
      })
      .catch((e) => {
        console.log(e);
        failed(e);
      });
  },

  getProposal: (
    id: any,
    success: (e?: any) => void,
    failed: (e?: any) => void
  ) => {
    ApiRequest.get(URL.PROPOSALS.PROPOSAL + id + '/')
      .then((response) => {
        console.log(response.data);
        success(response.data);
      })
      .catch((e) => {
        console.log(e.response.data);
        failed(e.response.data);
      });
  },

  addIDFront: (
    IDFront: any | null,
    success: (e?: any) => void,
    failed: (e?: any) => void
  ) => {
    ApiRequest.patch(URL.USER.PROFILE, {
      IDFront,
    })
      .then((response) => {
        success(response.data);
      })
      .catch((e) => {
        failed(e.response.data);
      });
  },

  upgradeAcc: (
    IDFront: any | null,
    IDBack: any | null,
    Bankproof: any | null,
    bankCode: string,
    bankAccountNumber: number,
    success: (e?: any) => void,
    failed: (e?: any) => void
  ) => {
    ApiRequest.patch(URL.USER.PROFILE, {
      IDFront,
      IDBack,
      Bankproof,
      bankCode,
      bankAccountNumber,
    })
      .then((response) => {
        console.log(response.data);
        success(response.data);
      })
      .catch((e) => {
        console.log(e.response.data);
        failed(e.response.data);
      });
  },

  activateAcc: (
    id: string,
    reference_id: string,
    Cardstatement: string,
    cardCutoff: string,
    cardLimit: string,
    success: (e?: any) => void,
    failed: (e?: any) => void
  ) => {
    ApiRequest.patch(URL.TRANSACTIONS.PAYMENT_SOURCE + '/' + id + '/', {
      id,
      reference_id,
      Cardstatement,
      cardCutoff,
      cardLimit,
    })
      .then((response) => {
        console.log(response.data);
        success(response.data);
      })
      .catch((e) => {
        console.log(e.response.data);
        failed(e.response.data);
      });
  },

  paymayaInit: () => {
    ApiRequest.post(URL.PAYMAYA.PAYMAYA_ACCOUNT)
      .then((response) => {
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e.response.data);
      });
  },

  installmentCompute: (
    amount: any | null,
    terms: any | null,
    success: (e?: any) => void,
    failed: (e?: any) => void
  ) => {
    HttpRequest.post(URL.TRANSACTIONS.COMPUTATION, {
      amount,
      terms,
    })
      .then((response) => {
        success(response.data);
      })
      .catch((e) => {
        failed(e.response.data);
      });
  },

  updateProfile: (
    first_name: string,
    last_name: string,
    gender: string,
    contact_number: string,
    street: string,
    street2: string,
    region: string,
    city: string,
    barangay: string,
    zip_code: string,
    success: (e?: any) => void,
    failed: (e?: any) => void
  ) => {
    ApiRequest.patch(URL.USER.PROFILE, {
      first_name,
      last_name,
      gender,
      contact_number,
      street,
      street2,
      region,
      city,
      barangay,
      zip_code,
    })
      .then((response) => {
        console.log(response.data);
        success(response.data);
      })
      .catch((e) => {
        console.log(e.response.data);
        failed(e.response.data);
      });
  },

  updateAvatar: (
    profile_picture: any,
    success: (e?: any) => void,
    failed: (e?: any) => void
  ) => {
    ApiRequest.patch(URL.USER.PROFILE, {
      profile_picture,
    })
      .then((response) => {
        console.log(response.data);
        success(response.data);
      })
      .catch((e) => {
        console.log(e.response.data);
        failed(e.response.data);
      });
  },

  getCards: (success: (e?: any) => void, failed: (e?: any) => void) => {
    ApiRequest.get(URL.TRANSACTIONS.PAYMENT_SOURCE)
      .then((response) => {
        success(response.data.results);
      })
      .catch((e) => {
        failed(e.response.data);
      });
  },

  logout: () => {
    localStorage.removeItem('admin-key');
    Cookies.remove('admin-key');
  },
};

export default Auth;
