import React, {useEffect, useRef, useState} from 'react';
import {
    useHistory, useParams
} from 'react-router-dom';
import {
    Container, 
    Card,
    CardContent,
    Button as MuiButton,
    TablePagination,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Checkbox,
    FormControlLabel,
    TextField,
    AppBar,
    Box,
    Toolbar,
    InputLabel,
    FormControl,
    MenuItem,
    Select,
    makeStyles,
    DialogActions,
    Dialog,
    DialogContent,
    DialogTitle
} from '@material-ui/core';
import {Row} from 'react-bootstrap';
import './style.scss';
import moment from 'moment';
import Auth from '../../auth';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import noImage from '../../assets/images/no_image.jpg';
import { useFormFiles } from '../../hooks';
import { SuccessDialog } from '../../components/SuccessDialog';
import { ErrorDialog } from '../../components/ErrorDialog';

interface ParamTypes {
    action: any | null;
    id?: any | null;
}

const useStyles = makeStyles({
    root: {
      borderColor: '#f47223',
    },
   
    dialogPaper: {
      height: '100%',
    },
    dialogTitle: {
      paddingTop: 15, 
      alignSelf: 'center', 
      justifySelf: 'center', 
      color: '#f47223',
      zIndex: 0,
    },
   
  });


export const Ads: React.FC<{
    setLoadingOverlay: (e?: boolean) => void;
}> = ({setLoadingOverlay}) => {
    const classes = useStyles()

    const createRef = useRef<any | null>(null) 
    const updateRef = useRef<any | null>(null) 

    const {action, id} = useParams<ParamTypes>()
    const history = useHistory();
    const [args, setArgs] = useState<any | null>('');
    const [count, setCount] = useState<any | null>(0);
    const [pageSize, setPageSize] = useState<any | null>(12)

    const [addImage, setAddImage] = useState<any | null>(null)
    const [editImage, setEditImage] = useState<any | null>(null)

    const {files, handleFiles} = useFormFiles({
        addImage: '',
        updateImage: ''
    })

    const [ads, setAds] = useState<any | null>(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(12);

    const [selectedAd, setSelectedAd] = useState<any>(null);

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMsg, setDialogMsg] = useState('');

    const [openError, setOpenError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('')

    const openAddRef = () => {
        console.log('openID')
        createRef.current.click()
    }

    const openUpdateRef = () => {
        console.log('openID')
        updateRef.current.click()
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        if(newPage !== page){
            console.log('PAGE: ',newPage+1)
            setPage(newPage);
            if((newPage+1) === 1){
                
                setArgs(`page=1`);
            }else{
                setArgs(`page=${newPage+1}`)
            }
        }
    };

    useEffect(() => {
        if(files.updateImage && selectedAd){
            updateAd()
        }
    },[files.updateImage])
    
    const updateAd = () => {
        Auth.dashboard.updateAd(
            selectedAd.id,
            files.updateImage,
            (resp:any) => {
                console.log(resp)
                let newArgs = `?page_size=${pageSize}&&`+args
                handleFiles({
                    target:{
                        name: 'updateImage',
                        files: null
                    },
                })
                loadAds(newArgs)
                setLoadingOverlay(false)
            },
            (e:any) => {
                console.log(e)
                setErrorMsg(e.error)
                setOpenError(true)
                setLoadingOverlay(false)
            }
        )
    }

    const deleteAd = () => {
        Auth.dashboard.deleteAd(
            selectedAd.id,
            (resp:any) => {
                console.log(resp)
                let newArgs = `?page_size=${pageSize}&&`+args
                setSelectedAd(null)
                history.push('/admin/ads')
                handleFiles({
                    target:{
                        name: 'updateImage',
                        files: null
                    },
                })
                loadAds(newArgs)
                setDialogMsg('Ad deleted.')
                setOpenDialog(true)
                setLoadingOverlay(false)
            },
            (e:any) => {
                console.log(e)
                setErrorMsg(e.error)
                setOpenError(true)
                setLoadingOverlay(false)
            }
        )
    }

    useEffect(() => {
        if(ads === null){
            console.log(action, id)
            setLoadingOverlay(true)
            
            
        }
        
    },[ads])

    useEffect(() => {
        if(files.addImage){
            Auth.dashboard.createAd(
                files.addImage,
                (resp: any) => {
                    console.log(resp)
                    setOpenDialog(true)
                    handleFiles({
                        target:{
                            name: 'addImage',
                            files: null
                        },
                    })
                    let newArgs = `?page_size=${pageSize}&&`+args
                    loadAds(newArgs)
                    setDialogMsg('Ad has been successfully uploaded.')
                },
                (e: any) => {
                    console.log(e)
                    setErrorMsg(e.error)
                    setOpenError(true)
                    setLoadingOverlay(false)
                }
            )
        }
    },[files.addImage])

    useEffect(() => {
        let newArgs = `?page_size=${pageSize}&&`+args
        loadAds(newArgs)
    },[args, pageSize])

    const loadAds = (params: any) =>{
        Auth.dashboard.getAds(
            params,
            (resp: any) => {
                console.log(resp)
                console.log(resp.results)
                setLoadingOverlay(false)
                setCount(resp.count)
                setAds(resp.results)
            },
            (e) => {
                console.log(e)
                setLoadingOverlay(false)
            }
        )
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPageSize(event.target.value);
        setPage(0);
    };

    return (
        <div className="page-content users">
            <Container>
                    <>
                   
                    <Row>
                        <AppBar position="static" style={{ background: 'transparent', boxShadow: 'none'}}>
                            <Toolbar>
                                <Typography style={{fontSize: '20px', fontWeight: 'bold'}} color="primary">
                                    Ads
                                </Typography>
                                <Box display='flex' flexGrow={1} />
                                <div className="button-tool" onClick={openAddRef}>
                                    <Typography style={{fontSize: 16, color: 'white'}}>Upload Ad</Typography>
                                </div>
                                <input 
                                    type="file" 
                                    ref={createRef} 
                                    name="addImage" 
                                    style={{display:'none'}}
                                    onChange={(e) => {
                                        if(e.target.files !== null){
                                            var FileSize = e.target.files[0].size / 1024 / 1024;
                                            if(FileSize > 2){
                                                
                                                alert("File must be below 2MB.")
                                            }else{
                                                handleFiles(e)
                                                console.log(e.target.files[0])
                                                setAddImage(e.target.files[0])
                                            }
                                        }
                                        
                                    } 
                                } />
                            </Toolbar>
                        </AppBar>
                    </Row>
                    <Row>
                        <Card>
                            <CardContent>
                                <Container>
                                    <Grid container direction="row" spacing={2} alignItems="center" justify="center">
                                        {(ads && ads.length > 0) && ads.map((ad: any) => (
                                            <Grid item xs={12} sm={6} lg={3} xl={2}>
                                                <div className={'img-container'} onClick={() => {setSelectedAd(ad); history.push(`/admin/ads/view/${ad.id}`)}}>
                                                    <img src={ad.image ? ad.image : noImage} />
                                                </div>
                                            </Grid>
                                        ))}
                                        
                                        
                                    </Grid>
                                    <TablePagination
                                        rowsPerPageOptions={[12]}
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        style={{float: 'right'}}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                    
                                </Container>
                            </CardContent>
                        </Card>
                    </Row>
                    </>
                
                
            </Container>
            <Dialog
                className={classes.dialogPaper} 
                open={id !== undefined && selectedAd } 
                maxWidth={'sm'}
                fullWidth
                PaperProps={{style: {
                    border: "1px solid #fee2b3",
                    borderRadius: '10px'
                }}}
            >
                <DialogTitle className={classes.dialogTitle}>
                    <span style={{color: '#f47223', fontWeight: 'bold', fontSize: '16px'}}>Update Image</span>
                    <img
                        src='https://d30y9cdsu7xlg0.cloudfront.net/png/53504-200.png'
                        style={{cursor:'pointer', position: 'absolute', right: '24px', marginTop: '5px', width: '20px'}}
                        onClick={()=>{setSelectedAd(null); history.push('/admin/ads'); setEditImage(null)}}/>
                </DialogTitle>
                <DialogContent style={{textAlign:'center', padding: '20px 0px'}}>
                    
                    <div className={'img-container-big'} >
                        <img src={editImage ? editImage : selectedAd && selectedAd.image ? selectedAd.image : noImage} />
                    </div>
                    <input 
                        type="file" 
                        ref={updateRef} 
                        name="updateImage" 
                        style={{display:'none'}}
                        onChange={(e) => {
                            if(e.target.files !== null){
                                var FileSize = e.target.files[0].size / 1024 / 1024;
                                if(FileSize > 2){
                                    
                                    alert("File must be below 2MB.")
                                }else{
                                    handleFiles(e)
                                    console.log(e.target.files[0])
                                    setEditImage(URL.createObjectURL(e.target.files[0]))
                                }
                            }
                            
                        } 
                    } />
                </DialogContent>
                <DialogActions>
                    <MuiButton variant="contained" onClick={openUpdateRef} style={{color: 'white', background:'#f47723'}}>
                        <span style={{color: 'white'}}>Update</span>
                    </MuiButton>
                    <MuiButton variant="contained" onClick={deleteAd} style={{color: 'white', background:'red'}}>
                        <span style={{color: 'white'}}>Delete</span>
                    </MuiButton>
                </DialogActions>
            </Dialog>
            <SuccessDialog openDialog={openDialog} dialogMessage={dialogMsg} setOpenDialog={setOpenDialog} onClose={() => {history.push('/admin/ads')}} />
            <ErrorDialog openDialog={openError} dialogMessage={errorMsg} setOpenDialog={setOpenError}  />
        </div>
    )
}