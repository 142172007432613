import React, { useState, useEffect, useContext } from 'react';
import { Dialog, DialogTitle, Grid, makeStyles, MenuItem, Select, Container } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DoneIcon from '@material-ui/icons/Done';
import './style.scss';
import close from '../../assets/images/close.png';

export const SuccessDialog: React.FC<{
    openDialog: boolean,
    setOpenDialog: (val: boolean) => void,
    onClose?: () => void,
    dialogMessage: string | null,
    link?: any | null,
}> = ({openDialog, setOpenDialog, dialogMessage, link, onClose}) => {
    const [copied, setCopied] = useState(false);
 
    return (
        <Dialog 
            open={openDialog} 
            fullWidth={true} 
            maxWidth={'xs'} 
            onClose={() =>{setOpenDialog(false); 
                if(onClose !== undefined) {
                    onClose()
                }
                
            }} 
            PaperProps={{
                style: {
                    backgroundColor: '#fee2b3', 
                    border: "1px solid #f47223",
                    borderRadius: '20px'
                },
            }}
        >
            <DialogTitle style={{color: 'green', textAlign: "center"}}>
                <img
                    src={close}
                    style={{cursor:'pointer', position: 'absolute', right: '24px', marginTop: '5px', width: '20px'}}
                    onClick={() =>{setOpenDialog(false); 
                        if(onClose !== undefined) {
                            onClose()
                        }
                        
                    }} 
                />
                
                <div style={{paddingTop: 10, color: "#f47223"}}>
                    Success
                </div>
                    
                
            </DialogTitle>
            <Container>
                <div style={{margin: "5px 25px 25px 25px", backgroundColor: 'white', border: '1px solid white', borderRadius: '20px'}}>
                    <div style={{padding: 20, textAlign: 'center'}}>
                        
                        <Grid container item direction="row" alignContent="center" justify="center">
                            <Grid item>
                                {dialogMessage}
                            </Grid>
                        </Grid>
                       
                    </div>
                </div>
                
            </Container>
        </Dialog>
    );
}
    