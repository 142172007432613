import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import React from "react";


export const SaveConfirmationDialog: React.FC<{
    open: boolean;
    setOpen: () => void;
    btnYes: () => void;
    btnNo: () => void;
}> = ({btnYes, btnNo, open, setOpen}) => 
    <Dialog open={open} maxWidth={'xs'}  fullWidth onClose={setOpen}>
        <DialogTitle>
            <Typography style={{fontSize: '20px', fontWeight: 'bold'}} color="primary">
                Save Changes?
            </Typography>
        </DialogTitle>
        
        <DialogActions>
            <div className="button-tool">
                <Typography style={{fontSize: 16, color: 'white'}} onClick={btnYes}>Yes</Typography>
            </div>
            <div className="button-delete">
                <Typography style={{fontSize: 16, color: 'white'}} onClick={btnNo}>No</Typography>
            </div>
        </DialogActions>
    </Dialog>