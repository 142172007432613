import { Autocomplete } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { TextField } from '@material-ui/core';
import { useQuery } from '@tanstack/react-query';
import URL from '../../services/url';

export interface Company {
  id: number;
  name: string;
}

type Variant = 'outlined' | 'standard';

type Props = {
  setSelectedCompanyID: Dispatch<SetStateAction<number>>;
  initialCompanyID?: number;
  variant: Variant;
  label?: string | null;
  labelColor?: string;
};

const CompaniesList: React.FC<Props> = ({
  setSelectedCompanyID,
  variant,
  initialCompanyID = 0,
  label = '',
  labelColor = 'black',
}: Props) => {
  const [companyName, setCompanyName] = useState<string>('');
  const { data, isLoading } = useQuery(['companiesData'], async () => {
    const response = await (await fetch(URL.COMPANIES.COMPANIES_LIST)).json();
    return response;
  });

  const CompaniesTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: 'black',
      },
      '& label': {
        color: 'black',
      },
      '& .MuiOutlinedInput-root': {
        color: 'black',
        '& fieldset': {
          color: 'black',
          borderColor: 'black',
        },
        '&:hover fieldset': {
          borderColor: 'black',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'black',
        },
      },
      '& .MuiInput-underline:after': {
        border: '1px black',
      },
    },
  })(TextField);

  const handleOnChange = (newValue: Company) => {
    if (newValue !== null) {
      setSelectedCompanyID(newValue.id);
      setCompanyName(newValue.name);
    } else {
      setSelectedCompanyID(initialCompanyID);
      setCompanyName('');
    }
  };

  if (isLoading) return <div></div>;

  return (
    <Autocomplete
      id="companies-list"
      options={data.results}
      getOptionLabel={(option: any) => option.name}
      onChange={(event, newValue) => handleOnChange(newValue!)}
      style={{ flex: 1 }}
      renderInput={(params) => (
        <CompaniesTextField
          {...params}
          style={{ width: '100%' }}
          label={companyName === '' ? label : ''}
          variant={variant}
          InputLabelProps={{ shrink: false }}
        />
      )}
    />
  );
};

export default CompaniesList;
