import React from 'react';
import './style.scss'

export const MoneyLabel: React.FC<{value: any, style?: string, isMoney: boolean}> = ({value, style, isMoney}) => {
    function numberWithCommas(x: any) {
        return 'PHP ' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    function labelWithCommas(x: any) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return (
        <div className={style}>
            {isMoney ? numberWithCommas(value) : labelWithCommas(value)}
        </div>
        
    )
}

